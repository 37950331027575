import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

export default function AlertDialog({ title, children, open, setOpen, onConfirm }) {
  return (
    <div>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={() => setOpen(false)}
        open={open}
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>CANCEL</Button>
          <Button autoFocus onClick={() => { setOpen(false); onConfirm(); }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}