import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import moment from "moment-timezone";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import {
  setStorage,
  getStorage,
  removeStorage,
} from "../helpers/storageHelper";
import { StorageKeys } from "../constants/StorageKeys";
import useAxios from "./useAxios";
import { useTimezone } from "./useTimezone";
import { removeUserPreferences } from "../helpers/userPreferenceHelper";
import { firebaseConfig } from "../config";
import useHttpError from "./useHttpError";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);
  const { get, put, post } = useAxios();
  const { handleHttpError } = useHttpError();
  const { onChange, timezone } = useTimezone();
  const [auth, setAuth] = useState({
    user: null,
    token: null,
    isAuthenticated: false,
    fcm: null,
  });

  useEffect(() => {
    if (!auth.isAuthenticated) {
      const token = getStorage(StorageKeys.TOKEN);
      if (token) {
        (async () => {
          await retrieveUser();
        })();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (auth.user && auth.isAuthenticated) {
      const { user } = auth;
      (async () => {
        onChange(user.timezone ? user.timezone : moment.tz.guess());
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user]);

  useEffect(() => {
    (async () => {
      const { user } = auth;
      if (timezone && timezone !== user.timezone) {
        await updateUserTimezone(timezone ? timezone : user.timezone);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezone]);

  useEffect(() => {
    (async () => {
      if (!auth.fcm && auth.isAuthenticated) {
        await setFcmToken();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    if (auth.fcm) {
      (async () => {
        await updateUserFcm(auth.fcm);
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.fcm]);

  const handleLogin = async (user, token) => {
    const origin = location.state?.from?.pathname || "/app/schedule-import";
    setStorage(StorageKeys.TOKEN, token);
    setAuth({
      ...auth,
      user,
      token,
      isAuthenticated: true,
    });
    navigate(origin);
  };

  const retrieveUser = async (isForce = false) => {
    const token = getStorage(StorageKeys.TOKEN);
    if (isForce || token !== auth.token) {
      const decodedToken = jwt_decode(token);
      const currentDate = new Date();

      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        handleLogout();
      } else {
        try {
          const user = await get("/users/me");

          if (user) {
            setAuth({
              ...auth,
              user,
              token,
              isAuthenticated: true,
            });
          }
        } catch (error) {
          handleHttpError(error);
        }
      }
    }
  };

  const handleLogout = () => {
    removeStorage("token");
    removeUserPreferences();
    setAuth({
      ...auth,
      user: null,
      token: null,
      isAuthenticated: false,
      fcm: null,
    });
    window.location.replace(
      `https://${process.env.REACT_APP_AUTH0_DOMAIN}/v2/logout?client_id=${process.env.REACT_APP_AUTH0_CLIENTID}`
    );
  };

  const updateUserTimezone = async (timezone) => {
    try {
      await put(`/users/me`, { timezone });
    } catch (error) {
      handleHttpError(error);
    }
  };

  const sendResetPassword = async () => {
    try {
      const response = await put(`/users/reset-password`);

      if (response) {
        if (response === "OK") {
          return "We've just sent you an email to reset your password.";
        } else {
          return response;
        }
      }
    } catch (error) {
      handleHttpError(error);
    }
  };

  const updateUserFcm = async (fcm) => {
    try {
      await post(`/auth/local/fcm`, { token: fcm });
    } catch (error) {
      handleHttpError(error);
    }
  };

  const setFcmToken = async () => {
    if (!("Notification" in window)) {
      console.warn("Browser does not support desktop notification");
    } else {
      await Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          getToken(messaging, {
            vapidKey: process.env.REACT_APP_FIREBASE_API_WEB_PUSH,
          })
            .then((currentToken) => {
              if (currentToken) {
                setAuth({ ...auth, fcm: currentToken });
              } else {
                console.warn(
                  "No registration token available. Request permission to generate one."
                );
              }
            })
            .catch((err) => {
              console.warn("An error occurred while retrieving token. ", err);
            });
        }
      });
    }
  };

  const value = useMemo(
    () => ({
      auth,
      handleLogin,
      handleLogout,
      sendResetPassword,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
