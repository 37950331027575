import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Drawer, Typography, Button, Avatar } from "@mui/material";
import { useTheme } from "@mui/styles";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { topMenu, mainMenu } from "./NavConfig";
import { useAuth } from "../../hooks/useAuth";
import LogOutButton from "../../components/LogOutButton";

const RootStyle = styled("div")(({ theme }) => ({
  typography: theme.typography.fontFamily,
  "& .MuiDrawer-root": {
    "& .MuiPaper-root": {
      borderRight: "1px dashed rgba(0, 0, 0, 0.12)",
      width: theme.measure.drawerWidth,
    },
    width: theme.measure.drawerWidth,
    bgcolor: "background.default",
    borderRightStyle: "dashed",
    color: theme.palette.primary.main,
    "& .simplebar-content": { width: theme.measure.drawerWidth },
    "& .logoutBtn": {
      color: theme.palette.primary.text,
      border: "none",
    },
    "& .email": {
      color: `${theme.palette.primary.main} !important`,
    },
  },

  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: theme.measure.drawerWidth,
  },
}));

const ScrollbarStyle = styled("div")(() => ({
  "& .simplebar-placeholder": { height: "0 !important" },
  "& .MuiBox-root": { marginBottom: "0" },
  "& .closeBtn": {
    position: "absolute",
    right: ".5rem",
    top: "1rem",
  },
  "& .menuSection": {
    marginBottom: "2rem",
    "& .MuiButtonBase-root": {
      margin: "0 10%",
      padding: "0",
      height: "2.4rem",
      "&::after": {
        content: '""',
        height: "1px",
        width: "100%",
        background: "rgba(255, 255, 255, .3)",
        borderRadius: "50px",
        position: "absolute",
        bottom: "0",
      },
    },

    "& .MuiList-root": {
      padding: "0 auto",
    },
    "& .MuiListItemText-root": {
      fontSize: "1rem",
    },
    "& .MuiListItemIcon-root": {
      position: "absolute",
      right: "0",
      minWidth: "14px",
    },
    "& .MuiSvgIcon-root": { width: "14px", height: "14px" },
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(8, 0),
  marginLeft: "calc(10% - 20px)",
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  "& *": { color: theme.palette.primary.text },
  "& .MuiTypography-root": {
    fontWeight: "bolder",
    marginBottom: ".2rem",
    color: theme.palette.primary.text,
  },
  "& .MuiAvatar-root": {
    width: "50px",
    height: "50px",
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiTypography-root": {
      fontSize: "1rem",
    },
    "& .MuiAvatar-root": {
      width: "60px",
      height: "60px",
    },
  },
}));

const SectionStyle = styled("div")(({ theme }) => ({
  typography: theme.typography.fontFamily,
  "& ul > :last-child": { "&:after": { display: "none" } },
  "& .sectionTitle": {
    marginLeft: "10%",
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    "& .titleIcon": {
      width: "1.3rem",
      height: "1.3rem",
      marginRight: ".5rem",
    },
    "& .MuiTypography-root": {
      fontSize: "1rem",
      fontWeight: "bolder",
    },
  },
}));

Sidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function Sidebar({ isOpenSidebar, onCloseSidebar }) {
  const { auth } = useAuth();
  const { pathname } = useLocation();
  const theme = useTheme();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <ScrollbarStyle>
      <Scrollbar
        sx={{
          height: 1,
          "& .simplebar-content": {
            height: 1,
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <Button className="closeBtn" onClick={onCloseSidebar}>
          <CloseRoundedIcon />
        </Button>

        <Box sx={{ mb: 5, mx: 2.5 }}>
          <AccountStyle>
            <Avatar
              alt="picture"
              src={
                process.env.PUBLIC_URL + "/static/images/avatar_default.jpeg"
              }
            />
            <Box sx={{ ml: 2 }}>
              <Typography className="email" variant="body2">
                {auth.user?.email}
              </Typography>
              <LogOutButton />
            </Box>
          </AccountStyle>
        </Box>

        <SectionStyle>
          <Box className="menuSection">
            <Box
              sx={{
                margin: "0 10% 2rem 10% !important",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                borderRadius: "10px",
                border: `2px solid ${theme.palette.primary.text}`,
                "&:hover": {
                  background: theme.palette.primary.text,
                  "& svg": { fill: theme.palette.primary.main },
                  "& .MuiListItemText-root": {
                    color: theme.palette.primary.main,
                  },
                },
                "& .MuiBox-root": {
                  width: "100%",
                },
                "& .MuiList-root": {
                  padding: "0",
                },
                "& .MuiButtonBase-root": {
                  display: "flex",
                  justifyContent: "center",
                },
                "& .MuiListItemIcon-root": {
                  minWidth: "0",
                  marginRight: ".5rem",
                },
                "& .MuiListItemText-root": {
                  fontFamily: "system-ui",
                  fontWeight: "bolder",
                  fontSize: "1.2rem",
                  textTransform: "uppercase",
                  padding: 0,
                  maxWidth: "fit-content",
                },
              }}
            >
              <NavSection navConfig={topMenu} />
            </Box>
          </Box>
          <Box className="menuSection">
            <NavSection navConfig={mainMenu} />
          </Box>
        </SectionStyle>
        <Box sx={{ flexGrow: 1 }} />
      </Scrollbar>
    </ScrollbarStyle>
  );

  return (
    <RootStyle>
      <Drawer
        PaperProps={{
          sx: {
            width: "20rem",
            background: theme.palette.primary.main,
            "& *": { color: theme.palette.primary.text },
            "& .MuiDivider-root": { background: theme.palette.primary.text },
            [theme.breakpoints.down("sm")]: {
              width: "100vw",
            },
          },
        }}
        onClose={onCloseSidebar}
        open={isOpenSidebar}
      >
        {renderContent}
      </Drawer>
    </RootStyle>
  );
}
