import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { mapModelsToState } from "../utils/analyseMappers";
import useAxios from "./useAxios";
import useHttpError from "./useHttpError";
const ModelContext = createContext();

export const ModelProvider = ({ children }) => {
  const { get } = useAxios();
  const { handleHttpError } = useHttpError();
  const [models, setModels] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await get("/models");
      const mappedModels = mapModelsToState(data);
      setModels(mappedModels);
    }
    try {
      fetchData();
    } catch (error) {
      handleHttpError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => ({
      models,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [models]
  );
  return (
    <ModelContext.Provider value={value}>{children}</ModelContext.Provider>
  );
};

export const useModel = () => {
  return useContext(ModelContext);
};
