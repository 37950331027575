import { ActivityTypes } from "../constants/ActivityTypes";
import { FATIGUE_SCORE_REFERENCE } from "./fatigueHelper";

export const FatigueStatusColors = {
  Max: "#222222",
  High: "#FF8F40",
  Medium: "#FFC032",
  Low: "#6BBE98",
  Unknown: "#cfd8dc",
};

export const FatigueStatus = {
  Low: {
    status: "Low",
    color: FatigueStatusColors.Low,
  },
  Medium: {
    status: "Medium",
    color: FatigueStatusColors.Medium,
  },
  High: {
    status: "High",
    color: FatigueStatusColors.High,
  },
  Max: {
    name: "Max",
    status: "High",
    color: FatigueStatusColors.Max,
  },
  Unknown: {
    status: "Unknown",
    color: FatigueStatusColors.Unknown,
  },
};

export function getFatigueStatus(fatigueLevel, displayMax) {
  if (!fatigueLevel) {
    return FatigueStatus.Unknown;
  }
  if (fatigueLevel < 4) {
    return FatigueStatus.Low;
  } else if (fatigueLevel >= 4 && fatigueLevel <= 5) {
    return FatigueStatus.Medium;
  } else if (fatigueLevel > 5 && fatigueLevel < 7) {
    return FatigueStatus.High;
  } else if (fatigueLevel === 7) {
    if (displayMax) {
      return FatigueStatus.Max;
    } else {
      return FatigueStatus.High;
    }
  }
}

export function getEventColor(event) {
  let { color } = getFatigueStatus(event.fatigueScore);

  if (event.fatigueScore === FATIGUE_SCORE_REFERENCE.MAX) {
    color = FatigueStatusColors.Max;
  }

  if (event.activity_type !== ActivityTypes.FLIGHT) {
    color = "#BBBBBB";
  }

  return {
    color,
  };
}
