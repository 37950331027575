import { useState } from "react";
import PropTypes from "prop-types";
import {
  PieChart,
  Pie,
  Cell,
  Sector,
  ResponsiveContainer,
  Text,
} from "recharts";
import Loader from "./Loader";

const COLORS = [
  "#1060ab",
  "#4080bc",
  "#6699c9",
  "#85add4",
  "#9dbddd",
  "#b1cae4",
  "#c1d5e9",
  "#cddded",
];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        endAngle={endAngle}
        fill={fill}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
      />
      <Sector
        cx={cx}
        cy={cy}
        endAngle={endAngle}
        fill={fill}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        startAngle={startAngle}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        fill="none"
        stroke={fill}
      />
      <circle cx={ex} cy={ey} fill={fill} r={2} stroke="none" />
      <Text
        fill="#333"
        textAnchor={textAnchor}
        width={130}
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
      >{`Factor: ${payload.name}`}</Text>
      <Text
        dy={18}
        fill="#999"
        textAnchor={textAnchor}
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
      >
        {`Rate ${(percent * 100).toFixed(2)}%`}
      </Text>
    </g>
  );
};

export default function PieChartReport({ data, isLoading }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  return isLoading ? ( //Checkif if is loading
    <Loader />
  ) : (
    <ResponsiveContainer height={300} width="100%">
      <PieChart height={300} width={300}>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={data}
          dataKey="value"
          innerRadius={60}
          onMouseEnter={onPieEnter}
          outerRadius={80}
        >
          {data.map((entry, index) => (
            <Cell fill={COLORS[index % COLORS.length]} key={`cell-${index}`} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}
PieChartReport.propTypes = {
  data: PropTypes.array,
};
