import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
// components
import Copyright from "../components/Copyright";
import { useAuth } from "../hooks/useAuth";
import { Navigate, useLocation } from "react-router-dom";

export const SignInPage = () => {
  const {
    auth: { isAuthenticated },
  } = useAuth();
  const location = useLocation();

  if (isAuthenticated) {
    return (
      <Navigate replace state={{ from: location }} to="/app/schedule-import" />
    );
  }

  const handleLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/api/connect/auth0`;
  };

  return (
    <Grid component="main" container sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        md={7}
        sm={4}
        sx={{
          backgroundImage: "url(./static/images/sign-in-background.webp)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        xs={false}
      />
      <Grid component={Paper} elevation={6} item md={5} sm={8} square xs={12}>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Welcome – Sign in on TimeWiks Flight
          </Typography>
          <Box sx={{ mt: 1 }}>
            <Button
              fullWidth
              onClick={handleLogin}
              sx={{ mt: 3, mb: 2 }}
              variant="contained"
            >
              Sign In
            </Button>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
