import PropTypes from "prop-types";
import { Box, TextField, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

export default function SearchTextField({ searchTerm, filterSearchTerms }) {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      paddingTop={2}
    >
      <TextField
        id="search-terms"
        inputProps={{
          maxLength: 10,
        }}
        label="Search"
        onChange={(e) => filterSearchTerms(e.target.value)}
        onKeyDown={handleKeyDown}
        value={searchTerm}
        variant="outlined"
      />
      <IconButton onClick={() => filterSearchTerms("")}>
        <ClearIcon />
      </IconButton>
    </Box>
  );
}

SearchTextField.propTypes = {
  searchTerm: PropTypes.string,
  filterSearchTerms: PropTypes.string,
};
