import { Button, styled } from "@mui/material";
import { useAuth } from "../hooks/useAuth";

export default function LogOutButton() {
  const {
    handleLogout,
    auth: { isAuthenticated },
  } = useAuth();

  const StyledButton = styled(Button)(({ theme }) => ({
    "&:hover": { background: "#f0ecf4", color: theme.palette.primary.main },
    background: theme.palette.primary.greenBlue,
    color: theme.palette.primary.text,
    lineHeight: "1",
    border: "2px solid #fff",
    borderRadius: "50px",
    textTransform: "none",
    padding: ".3rem 1rem",
    marginTop: ".2rem",
  }));

  return (
    isAuthenticated && (
      <StyledButton onClick={handleLogout}>Log out</StyledButton>
    )
  );
}
