import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MultipleSelectChip({
  selectorName,
  items,
  setItemName,
}) {
  const handleChange = (event, newValue) => {
    setItemName(
      // On autofill we get a stringified value.
      typeof newValue === "string" ? newValue.split(",") : newValue
    );
  };

  return (
    <Autocomplete
      disableCloseOnSelect
      getOptionLabel={(option) => option}
      id={`${selectorName}-multiple-chip-label`}
      multiple
      onChange={handleChange}
      options={items}
      renderInput={(params) => (
        <TextField
          {...params}
          label={selectorName}
          placeholder={selectorName}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            checked={selected}
            checkedIcon={checkedIcon}
            icon={icon}
            style={{ marginRight: 8 }}
          />
          {option}
        </li>
      )}
      style={{ width: 250, paddingRight: 15 }}
    />
  );
}

MultipleSelectChip.propTypes = {
  selectorName: PropTypes.string,
  items: PropTypes.array,
  setItemName: PropTypes.func,
};
