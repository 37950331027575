import { ModelTypes } from "../constants/ModelTypes";
import { getFatigueStatus } from "../helpers/fatigueColorHelper";
import { mapActivitiesToLocal } from "./activityMappers";

export function mapFatigueAnalysesToLocal(fatigueAnalyses) {
  if (fatigueAnalyses && fatigueAnalyses.length > 0) {
    return fatigueAnalyses.map((fatigueAnalyse) => {
      return mapFatigueAnalyseToLocal(fatigueAnalyse);
    });
  } else {
    return [];
  }
}

export function mapFatigueAnalyseToLocal(fatigueAnalyse) {
  return {
    uuid: fatigueAnalyse.uuid,
    filename: fatigueAnalyse.Filename,
    nbr_analyses: fatigueAnalyse.nbrOfFatigueScores,
    model_name: fatigueAnalyse.model ? fatigueAnalyse.model.Name : null,
    is_custom: fatigueAnalyse.customModel?.isCustom, 
    created_at: fatigueAnalyse.createdAt,
    is_completed: fatigueAnalyse.isCompleted,
  };
}

export function mapModelsToState(models) {
  if (models && models.length > 0) {
    return models.map(({ attributes }) => {
      return attributes;
    }).filter(model => !model.Type || model.Type?.trim().toLowerCase() === ModelTypes.GENERIC);
  }
  return [];
}

function convertParamsToCalculateApi(flightParams, item) {
  const { paramId, editedValue } = item;
  const newObject = {
    paramId,
    newValue: editedValue === "" ? null : editedValue,
  };
  flightParams.push(newObject);
  return flightParams;
}

export function mapParamsToApi(paramsList) {
  if (paramsList) {
    return paramsList.reduce(convertParamsToCalculateApi, []);
  }
}

export function mapToFatigueScoreTable(activities) {
  if (activities) {
    return activities.map((ea) => {
      const { line_number, fatigueScore, uuid } = ea;
      return {
        line_number,
        uuid,
        fatigueRisk: getFatigueStatus(fatigueScore, true).status,
        fatigueScore: fatigueScore,
        activity_end: ea.activity_end,
        activity_start: ea.activity_start,
        activity_type: ea.activity_type,
        employee_base: ea.employee_base,
        employee_matricule: ea.employee_matricule,
        flight_from: ea.flight_from,
        flight_number: ea.flight_number,
        flight_to: ea.flight_to,
        augmented_crew: ea.augmented_crew,
      };
    });
  } else {
    return [];
  }
}

export function mapMyAnalyseToLocal(analyse) {
  return {
    analyse: analyse.import,
    activities: mapActivitiesToLocal(analyse.activities),
  };
}
