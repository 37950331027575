import { Routes, Route, useLocation } from "react-router-dom";
// layouts
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
import AppLayout from "./layouts/app";
// public pages
import { SignInPage } from "./pages/SignInPage";

// components
import ProtectedRoute from "./components/routes/ProtectedRoute";
import ScheduleImportPage from "./pages/app/ScheduleImportPage";
import LoginRedirect from "./pages/LoginRedirect";
import FatigueAnalyseOverviewPage from "./pages/app/FatigueAnalyseOverviewPage";
import "./App.css";
import ChangeTimezonePage from "./pages/app/ChangeTimezonePage";
import ChangePasswordPage from "./pages/app/ChangePasswordPage";
import ErrorPage from "./pages/ErrorPage";
import { NoCompany } from "./constants/Errors";
import ErrorLayout from "./layouts/ErrorLayout";
import ContactPage from "./pages/ContactPage";

function AppRoutes() {
  let location = useLocation();
  let background = location.state && location.state.background;

  return (
    <>
      <Routes location={background || location}>
        <Route element={<LogoOnlyLayout />}>
          <Route element={<SignInPage />} index />
          <Route element={<SignInPage />} path="sign-in" />
          <Route
            element={<LoginRedirect />}
            exact
            path="connect/:providerName/redirect"
          />
        </Route>
        <Route
          element={
            <ProtectedRoute>
              <ErrorLayout />
            </ProtectedRoute>
          }
          path="/error"
        >
          <Route
            element={
              <ProtectedRoute>
                <ErrorPage type={NoCompany.constant} />
              </ProtectedRoute>
            }
            path="no-company"
          />
        </Route>
        <Route
          element={
            <ProtectedRoute>
              <AppLayout />
            </ProtectedRoute>
          }
          path="/app"
        >
          <Route element={<ScheduleImportPage />} index />
          <Route
            element={
              <ProtectedRoute>
                <ScheduleImportPage />
              </ProtectedRoute>
            }
            path="schedule-import"
          />
          <Route
            element={
              <ProtectedRoute>
                <FatigueAnalyseOverviewPage />
              </ProtectedRoute>
            }
            path="fatigue-analyses/:analyseId"
          />
          <Route
            element={
              <ProtectedRoute>
                <ChangePasswordPage />
              </ProtectedRoute>
            }
            path="settings/modify-my-password"
          />
          <Route
            element={
              <ProtectedRoute>
                <ChangeTimezonePage />
              </ProtectedRoute>
            }
            path="settings/modify-timezone"
          />
          <Route
            element={
              <ProtectedRoute>
                <ContactPage />
              </ProtectedRoute>
            }
            path="contact-support"
          />
        </Route>
      </Routes>
      {background && (
        <Routes>
          <Route
            element={
              <ProtectedRoute>
                <AppLayout />
              </ProtectedRoute>
            }
            path="/app"
          />
        </Routes>
      )}
    </>
  );
}

export default AppRoutes;
