import { Backdrop } from "@mui/material";
import { createContext, useContext, useMemo, useState } from "react";
import Loader from "../components/Loader";
const BackdropContext = createContext();

export const BackdropProvider = ({ children }) => {
  const [isBackdropOpen, setIsBackdropOpen] = useState({
    isOpen: false,
    message: null,
  });

  const handleCloseBackdrop = () => {
    setIsBackdropOpen((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  };
  const handleOpenBackdrop = (message) => {
    setIsBackdropOpen((prevState) => ({
      ...prevState,
      isOpen: true,
      message,
    }));
  };

  const value = useMemo(
    () => ({
      isBackdropOpen,
      handleCloseBackdrop,
      handleOpenBackdrop,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isBackdropOpen]
  );
  return (
    <BackdropContext.Provider value={value}>
      <Backdrop
        open={isBackdropOpen.isOpen}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Loader message={isBackdropOpen.message} />
      </Backdrop>
      {children}
    </BackdropContext.Provider>
  );
};

export const useBackdrop = () => {
  return useContext(BackdropContext);
};
