export const DefaultError = {
  constant: "default",
  title: "Something went wrong",
  message: "Please come back later.",
  illustrationUrl: "/static/images/error.webp",
};

export const NoCompany = {
  constant: "noCompany",
  title: "Your profile is not complete",
  message: "Please contact the support.",
  illustrationUrl: "/static/images/error.webp",
};

export const TEXT_INPUT_ERRORS = {
  TOO_SHORT: "Message is too short",
  TOO_LONG: "Message is too long",
  DOLLAR_SIGN_NOT_ALLOWED: "$ character is not allowed",
};
