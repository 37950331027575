import { createContext, useContext, useEffect, useMemo, useState } from "react";
import useAxios from "./useAxios";
import useHttpError from "./useHttpError";
const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const { get } = useAxios();
  const { handleHttpError } = useHttpError();
  const [appContext, setAppContext] = useState({});

  useEffect(() => {
    async function fetchData() {
      const {
        data: { attributes },
      } = await get("/context");
      setAppContext(attributes);
    }
    try {
      fetchData();
    } catch (error) {
      handleHttpError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => ({
      appContext,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appContext]
  );
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
  return useContext(AppContext);
};
