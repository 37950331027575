import { Colors } from "../../../constants/Colors";
import { Typography } from "@mui/material";
import { useAnalyse } from "../../../hooks/useAnalyse";

export default function ImportInformations() {
  const { myAnalyse } = useAnalyse();

  return (
    myAnalyse &&
    myAnalyse.analyse && (
      <div>
        <Typography
          component="h6"
          style={{ color: Colors.primary }}
          variant="h6"
        >
          {myAnalyse.analyse.Model}{myAnalyse.analyse.customModel?.isCustom && <span> Custom</span>} Model - {myAnalyse.analyse.Filename}
        </Typography>
      </div>
    )
  );
}
