/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useCallback, useState } from "react";
import moment from "moment-timezone";

const TimezoneContext = createContext();

export const TimezoneProvider = ({ children }) => {
  const [timezone, setTimezone] = useState(null);

  const value = {
    timezone,
    onChange: useCallback((tz) => {
      setTimezone(tz);
      moment.tz.setDefault(tz);
    }, []),
  };

  return (
    <TimezoneContext.Provider value={value}>
      {children}
    </TimezoneContext.Provider>
  );
};

export const useTimezone = () => {
  return useContext(TimezoneContext);
};
