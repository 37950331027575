export function getUserPreferences() {
  return JSON.parse(localStorage.getItem("userPreferences"));
} 

export function setUserPreferences(preferences) {
  localStorage.setItem("userPreferences", JSON.stringify(preferences));
}

export function removeUserPreferences() {
  localStorage.removeItem("userPreferences");
}