import { Button, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";

export default function Modal({
  title,
  subTitle,
  children,
  isOpen,
  handleClose,
  styles,
  isClosingCrossVisible = false,
  isOkButtonVisible = false,
}) {
  return (
    <Dialog
      maxWidth="md"
      onClose={() => handleClose(!isOpen)}
      open={isOpen}
      sx={styles}
    >
      <DialogTitle>{title}</DialogTitle>
      {isClosingCrossVisible && (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <DialogContent>
        <DialogContentText>{subTitle}</DialogContentText>
        {children}
      </DialogContent>
      {isOkButtonVisible && (
        <Button
          autoFocus
          onClick={() => handleClose(!isOpen)}
          sx={{ justifyContent: "flex-end !important" }}
        >
          OK
        </Button>
      )}
    </Dialog>
  );
}
Modal.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  children: PropTypes.any,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  styles: PropTypes.any,
};
