/* eslint-disable react/prop-types */
import { useState } from "react";
import PropTypes from "prop-types";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import Loader from "./Loader";

export default function HorizontalBarChart({ data, yKey, xKey, isLoading }) {
  const BAR_AXIS_SPACE = 60;
  const [isAnimation, setIsAnimation] = useState(false);

  const renderBarLabelList = ({ x, y, value }) => {
    return (
      value && (
        <g>
          <text fill="#000" x={x} y={y - 5}>
            {value}
          </text>
        </g>
      )
    );
  };

  const renderCustomizedLabel = ({ x, y, value, width }) => {
    return (
      value && (
        <g>
          <text
            dominantBaseline="middle"
            fill="#999999"
            textAnchor="middle"
            x={x + width + 28}
            y={y + 16}
          >
            {`${value.toFixed(2)}%`}
          </text>
        </g>
      )
    );
  };

  return isLoading ? (
    <Loader />
  ) : (
    <ResponsiveContainer height={70 * data.length} width="100%">
      <BarChart
        data={data}
        layout="vertical"
        margin={{ left: 10, right: BAR_AXIS_SPACE, bottom: 5 }}
      >
        <XAxis axisLine={false} hide type="number" />
        <YAxis dataKey={xKey} hide type="category" yAxisId={0} />
        <YAxis
          dataKey={yKey}
          hide
          orientation="right"
          type="category"
          yAxisId={1}
        />
        <Bar
          barSize={32}
          dataKey={yKey}
          fill="#1060ab"
          isAnimationActive={isAnimation}
          onAnimationEnd={() => setIsAnimation(false)}
        >
          <LabelList
            content={renderBarLabelList}
            dataKey={xKey}
            position="top"
          />
          <LabelList
            content={renderCustomizedLabel}
            dataKey={yKey}
            position="right"
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
HorizontalBarChart.propTypes = {
  data: PropTypes.array,
  yKey: PropTypes.string,
  xKey: PropTypes.string,
};
