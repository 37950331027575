import { useParams } from "react-router-dom";
import { Typography, Grid } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// components
import Page from "../../components/Page";
import CustomPaper from "../../components/CustomPaper";
import { AnalyseProvider } from "../../hooks/useAnalyse";
import ContributingFactors from "../../components/app/fatigue/ContributingFactors";
import FatigueScoresDistribution from "../../components/app/fatigue/FatigueScoresDistribution";
import HeaderDivider from "../../components/HeaderDivider";
import { Colors } from "../../constants/Colors";
import TopNavButton from "../../components/TopNavButton";
import ImportInformations from "../../components/app/fatigue-analyses/ImportInformations";
import PredictedFatigueTabs from "../../components/app/fatigue/PredictedFatigueTabs";
import ActivitiesFilter from "../../components/app/fatigue/ActivitiesFilter";

export default function FatigueAnalyseOverviewPage() {
  let params = useParams();

  return (
    <Page title="Fatigue Analysis Overview">
      <AnalyseProvider importId={params.analyseId}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <CustomPaper>
              <Typography
                component="h5"
                style={{ color: Colors.primary }}
                variant="h4"
              >
                Fatigue Analysis Overview
              </Typography>
              <ImportInformations />
              <HeaderDivider />
              <TopNavButton
                destination="/app/schedule-import"
                icon={<ArrowBackIcon />}
                label="Back"
                style={{ marginRight: 10 }}
                variant="outlined"
              />
            </CustomPaper>
          </Grid>
          <Grid item md={6}>
            <CustomPaper
              elevation={0}
              square
              style={{ padding: 10 }}
              variant="outlined"
            >
              <ContributingFactors analyseId={params.analyseId} />
            </CustomPaper>
          </Grid>
          <Grid item md={6}>
            <CustomPaper
              elevation={0}
              square
              style={{ padding: 10 }}
              variant="outlined"
            >
              <FatigueScoresDistribution analyseId={params.analyseId} />
            </CustomPaper>
          </Grid>
          <Grid item xs={12}>
            <CustomPaper
              elevation={0}
              square
              style={{ padding: 10 }}
              variant="outlined"
            >
              <ActivitiesFilter />
            </CustomPaper>
          </Grid>
          <Grid item xs={12}>
            <PredictedFatigueTabs params={params} />
          </Grid>
        </Grid>
      </AnalyseProvider>
    </Page>
  );
}
