import { useState } from "react";
import PropTypes from "prop-types";
import HelpIcon from "@mui/icons-material/Help";
import { makeStyles } from "@mui/styles";
import { Button, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
    marginTop: 20,
  },
  imageContainer: {
    display: "flex",
    position: "relative",
    flexDirection: "flex-start",
    alignItems: "center",
  },
  showButton: {
    paddingLeft: 10,
  },
  text: {
    paddingTop: 15,
    textAlign: "justify",
    whiteSpace: "pre-line",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "flex-start",
  },
}));

export default function DisplayContext({
  boxStyle,
  text,
  textStyle,
  isShowed = false,
}) {
  const classes = useStyles();
  const [show, setShow] = useState(isShowed);
  return (
    <div className={classes.root} style={boxStyle}>
      <div className={classes.imageContainer}>
        <HelpIcon />
        <Button
          className={classes.showButton}
          color="primary"
          onClick={() => setShow((prev) => !prev)}
        >
          {show ? "Hide" : "Show"} Informations
        </Button>
      </div>
      {show && (
        <Typography className={classes.text} style={textStyle}>
          {text}
        </Typography>
      )}
    </div>
  );
}
DisplayContext.propTypes = {
  boxStyle: PropTypes.object,
  text: PropTypes.string,
  textStyle: PropTypes.object,
  isShowed: PropTypes.bool,
};
