import { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useTimezone } from "../../hooks/useTimezone";
import Loader from "../Loader";
import { hardCodedTimezones } from "../../constants/timezones";

export default function GlobalTimezoneSelector() {
  const [allZones, setAllZones] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { timezone, onChange } = useTimezone();

  useEffect(() => {
    let timezones = [];
    if (!Intl.supportedValuesOf) {
      console.warn("Your browser does not support Intl.supportedValuesOf().");
      timezones = hardCodedTimezones;
    } else {
      timezones = Intl.supportedValuesOf("timeZone");
    }
    timezones.unshift("UTC");
    setAllZones(timezones);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event, newValue) =>
    onChange(
      newValue ? newValue : Intl.DateTimeFormat().resolvedOptions().timeZone
    );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Autocomplete
      disablePortal
      id="autocomplete-timezone"
      onChange={handleChange}
      options={allZones}
      renderInput={(params) => (
        <TextField {...params} label="Current timezone" />
      )}
      sx={{ width: "auto" }}
      value={timezone}
    />
  );
}
