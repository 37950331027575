import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useAnalyse } from "../../../hooks/useAnalyse";
import {
  employeeFlightsToGroup,
  employeeFlightsToItems,
  groupFlightsByEmployees,
} from "../../../utils/timelineMappers";
import Loader from "../../Loader";
import Timeline from "../timeline/Timeline";

export default function FatigueTimeline({ analyseId, timezone }) {
  const [isLoading, setIsLoading] = useState(true);
  const { filteredActivities } = useAnalyse();

  useEffect(() => {
    if (filteredActivities) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [filteredActivities]);

  const displayTimeline = () => {
    const employeesGrouped = groupFlightsByEmployees(filteredActivities);
    const resources = employeeFlightsToGroup(employeesGrouped);
    const events = employeeFlightsToItems(employeesGrouped);

    return (
      <Timeline
        analyseId={analyseId}
        events={events}
        initialTimeZone={timezone}
        resources={resources}
      />
    );
  };

  return isLoading ? <Loader /> : displayTimeline();
}

FatigueTimeline.propTypes = {
  analyseId: PropTypes.any,
  timezone: PropTypes.string,
};
