import { ActivityTypes } from "../constants/ActivityTypes";
import { getEventColor } from "../helpers/fatigueColorHelper";

export function groupFlightsByEmployees(employeesFlights) {
  const groupByEmployees = Object.values(
    employeesFlights.reduce((acc, current) => {
      (
        acc[current.employee_matricule] ||
        (acc[current.employee_matricule] = [])
      ).push(current);
      return acc;
    }, {})
  );

  return groupByEmployees.reduce((acc, current) => {
    let newObject = { employee: {}, activities: [] };
    current.map((item) => {
      newObject.employee = {
        matricule: item.employee_matricule,
        base: item.employee_base,
      };
      return newObject.activities.push(item);
    });
    if (newObject.activities.length > 0) acc.push(newObject);
    return acc;
  }, []);
}

export function employeeFlightsToGroup(employeesFlights) {
  return employeesFlights.map((ef) => {
    const { employee } = ef;
    return {
      id: employee.matricule,
      matricule: employee.matricule,
    };
  });
}

export function employeeFlightsToItems(employeesFlights) {
  let items = [];
  employeesFlights.map((ef) => {
    const { employee, activities } = ef;
    return activities.map((activity) => {
      const eventColor = getEventColor(activity);
      return items.push({
        id: activity.uuid,
        resourceId: employee.matricule,
        title:
          activity.activity_type === ActivityTypes.FLIGHT
            ? `${activity.flight_from} – ${activity.flight_to}`
            : activity.activity_type,
        start: activity.activity_start,
        end: activity.activity_end,
        from: activity.flight_from,
        to: activity.flight_to,
        fatigueScore: activity.fatigueScore,
        activity_type: activity.activity_type,
        flight_number: activity.flight_number
          ? activity.flight_number.toUpperCase()
          : null,
        canMove: false,
        ...eventColor,
      });
    });
  });
  return items;
}
