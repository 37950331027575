import {
  FatigueStatus,
  FatigueStatusColors,
  getFatigueStatus,
} from "./fatigueColorHelper";

export const FATIGUE_SCORE_REFERENCE = {
  MIN: 1,
  MAX: 7,
};

export function countFatigueScoreDistribution(fatigueScores) {
  const fatigueScoreList = fatigueScores.map((fs) => {
    return getFatigueStatus(fs, false);
  });

  const countsByLevel = fatigueScoreList.reduce((p, c) => {
    let status = c.status;
    if (status !== FatigueStatus.unknown) {
      if (!p[status]) {
        p[status] = { count: 0, color: c.color };
      }
      p[status].count++;
    }
    return p;
  }, {});

  return Object.keys(FatigueStatus)
    .filter((name) => name !== FatigueStatus.Unknown.status)
    .map((fatigueStatusName) => {
      const currentFatigueStatus = countsByLevel[fatigueStatusName];
      return {
        name: fatigueStatusName,
        fl: currentFatigueStatus ? currentFatigueStatus.count : 0,
        color: currentFatigueStatus
          ? currentFatigueStatus.color
          : FatigueStatusColors.Unknown,
      };
    });
}
