import PropTypes from "prop-types";
import { Box, Button, Container, Typography } from "@mui/material";
import Modal from "../../Modal";
import HeaderDivider from "../../HeaderDivider";
import { Colors } from "../../../constants/Colors";
import { useModel } from "../../../hooks/useModel";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  containedButton: {
    color: "#fff",
    marginRight: 10,
    borderRadius: 50,
    backgroundColor: "#C90F5C",
    "&:hover": {
      backgroundColor: "#C90F5C",
      color: "#E4E4E4",
    },
  },
  importButtonsBox: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function ImportModal({ file, handleClose }) {
  const classes = useStyles();
  const { models } = useModel();

  return (
    <Modal
      disableBackdropClick
      handleClose={handleClose}
      isOpen={file ? true : false}
    >
      <Container maxWidth="md" style={{ marginBottom: 25 }}>
        <Box>
          <Typography style={{ color: Colors.lightGreen }} variant="h4">
            Import your file
          </Typography>
          <Typography style={{ textAlign: "center" }}>{file.name}</Typography>
        </Box>
        <Box style={{ marginTop: 30 }}>
          <Typography component="h6" variant="h5">
            Choose the population :
          </Typography>
          <HeaderDivider />
          <Box className={classes.importButtonsBox}>
            {models.map((choice, idx) => {
              return (
                <Button
                  className={classes.containedButton}
                  key={idx}
                  onClick={() => handleClose(choice.uuid)}
                  variant="contained"
                >
                  {choice.Name}
                </Button>
              );
            })}
          </Box>
        </Box>
      </Container>
    </Modal>
  );
}

ImportModal.propTypes = {
  file: PropTypes.any,
  handleClose: PropTypes.func,
};
