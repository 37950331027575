import { createTheme } from "@mui/material";

const theme = createTheme({
  measure: {
    drawerWidth: "350px",
  },
  palette: {
    primary: {
      main: "#001a45",
      secondary: "#072F5B",
      text: "#fff",
      brandViolet: "#E93F90",
      greenBlue: "#29B8C5",
    },
    generic: {
      grey: "#bdbdbd",
    },
  },
  typography: {
    fontFamily: [
      "Carnas-Regular",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  mixins: {
    flexCenter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          cursor: "pointer",
        },
      },
    },
  },
});

export default theme;
