import { getFatigueStatus } from "../helpers/fatigueColorHelper";

export function mapActivityToLocal(activity) {
  if (activity) {
    const { status } = getFatigueStatus(activity.fatigueScore, true);
    return {
      ...activity,
      fatigueRisk: status,
      augmented_crew: activity.augmented_crew === null ? false : (typeof activity.augmented_crew === 'boolean' ? activity.augmented_crew : false)
    };
  } else {
    return null;
  }
}

export function mapActivitiesToLocal(activities) {
  if (activities) {
    return activities.map((activity) => {
      return mapActivityToLocal(activity);
    });
  } else {
    return [];
  }
}
