import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { Colors } from "../../../constants/Colors";
import PieChartReport from "../../PieChartReport";
import HeaderDivider from "../../HeaderDivider";
import DisplayContext from "../DisplayContext";
import { useAppContext } from "../../../hooks/useAppContext";
import { useAnalyse } from "../../../hooks/useAnalyse";

export default function ContributingFactors({ analyseId }) {
  const [contributingFactors, setContributingFactors] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const { callGetParamsFactorsContribution } = useAnalyse();
  const {
    appContext: { fatigue_analyse_causes_informations },
  } = useAppContext();

  useEffect(() => {
    const fetchData = async () => {
      const data = await callGetParamsFactorsContribution(analyseId);
      setContributingFactors(data);
      setLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyseId]);

  return (
    <>
      <Typography component="h6" style={{ color: Colors.thirdy }} variant="h5">
        Main causes of fatigue risks
      </Typography>
      <HeaderDivider />
      <PieChartReport data={contributingFactors} isLoading={isLoading} />
      <DisplayContext
        boxStyle={{ marginTop: 0 }}
        isShowed={true}
        text={fatigue_analyse_causes_informations}
        textStyle={{ paddingLeft: 0 }}
      />
    </>
  );
}
