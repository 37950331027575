import { ToastContainer } from "react-toastify";

export const ToasterProviderWithConfig = () => {
  return (
    <ToastContainer
      autoClose={10000}
      closeOnClick
      hideProgressBar={false}
      newestOnTop={false}
      pauseOnFocusLoss
      pauseOnHover
      position="bottom-center"
      rtl={false}
      theme="light"
    />
  );
};
