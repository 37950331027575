import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";

export default function DataTable({ title, data, columns, options }) {
  const defaultOptions = {
    responsive: "standard",
  };
  return (
    <MUIDataTable
      columns={columns}
      data={data}
      options={{ ...options, ...defaultOptions }}
      title={title}
    />
  );
}
DataTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  columns: PropTypes.array,
  options: PropTypes.object,
};
