import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Label,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Loader from "./Loader";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
  },
}));

const CustomizedTooltip = ({ label, payload, active, tooltipLabel }) => {
  const classes = useStyles();
  if (!active || !label || payload.length === 0) return null;

  return (
    <div className={classes.tooltip}>
      <span>{`${tooltipLabel}: ${payload[0].payload.fl}`}</span>
    </div>
  );
};
CustomizedTooltip.propTypes = {
  label: PropTypes.string,
  payload: PropTypes.array,
  active: PropTypes.bool,
  tooltipLabel: PropTypes.string,
};
export default function BarChartReport({
  data,
  xAxisOptions,
  xAxisVerticalLabel,
  tooltipLabel,
  isLoading,
}) {
  return isLoading ? (
    <Loader />
  ) : (
    <ResponsiveContainer height={300} width="100%">
      <BarChart
        data={data}
        height={300}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        width={500}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis {...xAxisOptions}>
          {xAxisVerticalLabel && (
            <Label
              angle={-90}
              position="insideLeft"
              style={{ textAnchor: "middle" }}
              value={xAxisVerticalLabel}
            />
          )}
        </YAxis>
        <Tooltip content={<CustomizedTooltip tooltipLabel={tooltipLabel} />} />
        <Bar dataKey="fl">
          {data.map((entry, index) => (
            <Cell fill={entry.color} key={`cell-${index}`} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
BarChartReport.propTypes = {
  data: PropTypes.array,
  xAxisOptions: PropTypes.object,
  xAxisVerticalLabel: PropTypes.string,
  tooltipLabel: PropTypes.string,
};
