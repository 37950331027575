import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { MyAnalysesProvider } from "../../hooks/useMyAnalyses";
import { AppContextProvider } from "../../hooks/useAppContext";
import { useAuth } from "../../hooks/useAuth";
import { ModalProvider } from "../../hooks/useModal";

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")({});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(8),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 8,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function AppLayout() {
  const navigate = useNavigate();

  const { auth } = useAuth();
  const [open, setOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    if (auth.user.company === undefined || !auth.user.company) {
      navigate("/error/no-company");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RootStyle>
      <Header onOpenSidebar={() => setOpen(true)} />
      <Sidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle>
        <ModalProvider>
          <MyAnalysesProvider>
            <AppContextProvider>
              <Outlet context={[isOpenModal, setIsOpenModal]} />
            </AppContextProvider>
          </MyAnalysesProvider>
        </ModalProvider>
      </MainStyle>
    </RootStyle>
  );
}
