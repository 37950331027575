export function roundValue(value) {
  return Math.round((value + Number.EPSILON) * 100) / 100;
}

export function averageFatigueScoreFromFlights(flightList) {
  var total = 0;
  var count = 0;

  flightList.forEach((item) => {
    total += item.fatigueScoreValue;
    count++;
  });

  return total / count;
}

export function maxFatigueFatigueScore(flightList) {
  return Math.max.apply(
    Math,
    flightList.map((o) => {
      return o.fatigueScoreValue;
    })
  );
}

export function getPercentageNumber(portion, total) {
  return Number(((portion / total) * 100).toFixed(2));
}

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    "Bytes",
    "KiB",
    "MiB",
    "GiB",
    "TiB",
    "PiB",
    "EiB",
    "ZiB",
    "YiB",
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
