/* eslint-disable no-unused-vars */
import Page from "../components/Page";
import { Box, Paper, Typography, styled } from "@mui/material";
import EmailComponent from "../components/app/email/EmailComponent";

export default function ContactPage() {
  const StyledInfoContainer = styled(Box)(({ theme }) => ({
    display: "grid",
    gap: "10px",
    color: theme.palette.primary.main,
    "& h1": {
      fontSize: "60px",
      fontWeight: "700",
      "@media (max-width: 768px)": {
        fontSize: "40px",
      },
    },
    "& span": {
      fontSize: "18px",
      lineHeight: "1.4",
      "@media (max-width: 768px)": {
        fontSize: "16px",
      },
    },
  }));

  return (
    <Page style={{ margin: "auto" }} title="Contact">
      <Paper
        elevation={0}
        square
        sx={{
          display: "grid",
          maxWidth: "800px",
          margin: "40px",
          padding: "40px",
          gap: "30px",
          "@media (max-width: 768px)": {
            margin: "10px",
            padding: "20px",
          },
        }}
        variant="outlined"
      >
        <StyledInfoContainer>
          <Typography variant="h1">Contact Us</Typography>
          <Typography variant="subtile">
            Do you have a question or comment? <br />
            Don&apos;t hesitate to contact us, we&apos;ll get back to you as
            soon as possible.
          </Typography>
        </StyledInfoContainer>
        <EmailComponent />
      </Paper>
    </Page>
  );
}
