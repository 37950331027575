import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

export default function CustomTabPanel({ children, value, index, ...other }) {
  return (
    <div
      aria-labelledby={`data-views-${index}`}
      hidden={value !== index}
      id={`data-views-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
