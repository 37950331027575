export const appConfig = {
  env: process.env.REACT_APP_ENV,
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

export const fullcalendarConfig = {
  licenceKey: process.env.REACT_APP_FULLCALENDAR_LICENCE_KEY,
};
