import { Typography, Grid, Button } from "@mui/material";
// components
import CustomPaper from "../../components/CustomPaper";
import Page from "../../components/Page";
import HeaderDivider from "../../components/HeaderDivider";
import { Colors } from "../../constants/Colors";
import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";

export default function ChangePasswordPage() {
  const { sendResetPassword } = useAuth();
  const [resetMessage, setResetMessage] = useState(null);

  const onResetClick = async () => {
    const response = await sendResetPassword();
    setResetMessage(response);
  };
  return (
    <Page title="Change my password">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomPaper>
            <Typography
              component="h5"
              style={{ color: Colors.primary }}
              variant="h4"
            >
              Change my password
            </Typography>
            <HeaderDivider />
          </CustomPaper>
        </Grid>
        <Grid item md={6}>
          <CustomPaper>
            <Typography component="div" gutterBottom variant="h6">
              Click here to reset your password :
            </Typography>
            <Button
              disabled={resetMessage}
              onClick={() => onResetClick()}
              variant="contained"
            >
              Reset my Password
            </Button>
            <Typography>{resetMessage}</Typography>
          </CustomPaper>
        </Grid>
      </Grid>
    </Page>
  );
}
