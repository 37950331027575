import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
// hooks
import { useTimezone } from "../../../hooks/useTimezone";
// components
import FatigueTimeline from "./FatigueTimeline";
import FatigueLevelTable from "../../app/fatigue/FatigueLevelTable";
import CustomTabPanel from "../../CustomTabPanel";
import { useAnalyse } from "../../../hooks/useAnalyse";

export default function PredictedFatigueTabs({ params }) {
  const { filteredActivities } = useAnalyse();
  const [value, setValue] = useState(0);
  const { timezone } = useTimezone();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          aria-label="data views"
          onChange={handleChange}
          value={value}
          variant="fullWidth"
        >
          <Tab id={0} label="TABLE VIEW" />
          <Tab id={1} label="TIMELINE VIEW" />
        </Tabs>
      </Box>
      <CustomTabPanel disabled={!filteredActivities} index={0} value={value}>
        <FatigueLevelTable analyseId={params.analyseId} timezone={timezone} />
      </CustomTabPanel>
      <CustomTabPanel disabled={!filteredActivities} index={1} value={value}>
        <FatigueTimeline analyseId={params.analyseId} timezone={timezone} />
      </CustomTabPanel>
    </Box>
  );
}
