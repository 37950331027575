import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";
import { firebaseConfig } from "./config";

const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
