import { useEffect, useState } from "react";
import { Colors } from "../../../constants/Colors";
import { useAnalyse } from "../../../hooks/useAnalyse";
import { Typography } from "@mui/material";
import BarChartReport from "../../BarChartReport";
import HeaderDivider from "../../HeaderDivider";
import DisplayContext from "../DisplayContext";
import { useAppContext } from "../../../hooks/useAppContext";

export default function FatigueScoresDistribution({ analyseId }) {
  const [fatigueLevelDistribution, setFatigueLevelDistribution] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const { callFatigueLevelDistribution } = useAnalyse();
  const {
    appContext: { fatigue_analyse_distribution_informations },
  } = useAppContext();

  useEffect(() => {
    const fetchData = async () => {
      const data = await callFatigueLevelDistribution(analyseId);
      setFatigueLevelDistribution(data);
      setLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyseId]);

  return (
    fatigueLevelDistribution && (
      <>
        <Typography
          component="h6"
          style={{ color: Colors.thirdy }}
          variant="h5"
        >
          Distribution of fatigue scores
        </Typography>
        <HeaderDivider />
        <BarChartReport
          data={fatigueLevelDistribution}
          isLoading={isLoading}
          tooltipLabel="Predicted fatigue score(s)"
          xAxisOptions={{ allowDecimals: false }}
          xAxisVerticalLabel="Number of fatigue scores"
        />
        <DisplayContext
          boxStyle={{ marginTop: 0 }}
          isShowed={true}
          text={fatigue_analyse_distribution_informations}
          textStyle={{ paddingLeft: 0 }}
        />
      </>
    )
  );
}
