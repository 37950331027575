import { createContext, useContext, useEffect, useMemo, useState } from "react";
import useAxios from "./useAxios";
import { ActivityColumnFilters } from "../constants/ActivityColumnFilters";
import { FatigueStatus } from "../helpers/fatigueColorHelper";
import { countFatigueScoreDistribution } from "../helpers/fatigueHelper";
import { isBooleanInArray, isStringIsInArray, mapYesNoArrayToBoolean } from "../helpers/arrayHelper";
import { mapMyAnalyseToLocal } from "../utils/analyseMappers";
import useHttpError from "./useHttpError";
import { NO, YES } from "../constants/Common";

const AnalyseContext = createContext();

export const AnalyseProvider = ({ importId, children }) => {
  const [myAnalyse, setMyAnalyse] = useState(null);
  const [filteredActivities, setFilteredActivities] = useState(null);
  const { get, isLoading } = useAxios();
  const { handleHttpError } = useHttpError();

  // Filters
  const [filters, setFilters] = useState({
    searchTerm: "",
    selectedFatigueRisks: [],
    selectedEmployeeIds: [],
    selectedEmployeeBases: [],
    selectedActivityTypes: [],
    selectedFlightNumbers: [],
    selectedFromList: [],
    selectedToList: [],
    selectedAugmentedCrew: []
  });

  useEffect(() => {
    if (importId && !myAnalyse) {
      callSetActivitiesListByImport(importId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importId, myAnalyse]);

  // Filter useEffect
  useEffect(() => {
    if (myAnalyse && myAnalyse.activities.length > 0) {
      const { activities } = myAnalyse;
      let filteredActivities = activities.filter((activity) => {
        let drop = true;
        for (const key in activity) {
          if (
            key === ActivityColumnFilters.FATIGUE_RISK &&
            filters.selectedFatigueRisks &&
            filters.selectedFatigueRisks.length > 0
          ) {
            let keep = false;
            keep =
              keep ||
              isStringIsInArray(
                filters.selectedFatigueRisks,
                activity.fatigueRisk
              );
            drop = drop && keep;
          }

          if (
            key === ActivityColumnFilters.EMPLOYEE_MATRICULE &&
            filters.selectedEmployeeIds &&
            filters.selectedEmployeeIds.length > 0
          ) {
            let keep = false;
            keep =
              keep ||
              isStringIsInArray(
                filters.selectedEmployeeIds,
                activity.employee_matricule
              );
            drop = drop && keep;
          }

          if (
            key === ActivityColumnFilters.EMPLOYEE_BASE &&
            filters.selectedEmployeeBases &&
            filters.selectedEmployeeBases.length > 0
          ) {
            let keep = false;
            keep =
              keep ||
              isStringIsInArray(
                filters.selectedEmployeeBases,
                activity.employee_base
              );
            drop = drop && keep;
          }

          if (
            key === ActivityColumnFilters.ACTIVITY_TYPE &&
            filters.selectedActivityTypes &&
            filters.selectedActivityTypes.length > 0
          ) {
            let keep = false;
            keep =
              keep ||
              isStringIsInArray(
                filters.selectedActivityTypes,
                activity.activity_type
              );
            drop = drop && keep;
          }

          if (
            key === ActivityColumnFilters.FLIGHT_NUMBER &&
            filters.selectedFlightNumbers &&
            filters.selectedFlightNumbers.length > 0
          ) {
            let keep = false;
            keep =
              keep ||
              isStringIsInArray(
                filters.selectedFlightNumbers,
                activity.flight_number
              );
            drop = drop && keep;
          }

          if (
            key === ActivityColumnFilters.FLIGHT_FROM &&
            filters.selectedFromList &&
            filters.selectedFromList.length > 0
          ) {
            let keep = false;
            keep =
              keep ||
              isStringIsInArray(filters.selectedFromList, activity.flight_from);
            drop = drop && keep;
          }

          if (
            key === ActivityColumnFilters.FLIGHT_TO &&
            filters.selectedToList &&
            filters.selectedToList.length > 0
          ) {
            let keep = false;
            keep =
              keep ||
              isStringIsInArray(filters.selectedToList, activity.flight_to);
            drop = drop && keep;
          }

          if (
            key === ActivityColumnFilters.AUGMENTED_CREW &&
            filters.selectedAugmentedCrew &&
            filters.selectedAugmentedCrew.length > 0
          ) {
            let keep = false;
            keep =
              keep ||
              isBooleanInArray(
                mapYesNoArrayToBoolean(filters.selectedAugmentedCrew),
                activity.augmented_crew
              );
            drop = drop && keep;
          }
        }

        return drop;
      });

      if (filteredActivities && filteredActivities.length > 0) {
        if (filters.searchTerm.length > 0) {
          const searchTermsLowerCase = filters.searchTerm.toLowerCase();
          filteredActivities = filteredActivities.filter((a) => {
            return (
              (a[ActivityColumnFilters.FATIGUE_RISK] &&
                a[ActivityColumnFilters.FATIGUE_RISK]
                  .toLowerCase()
                  .includes(searchTermsLowerCase)) ||
              (a[ActivityColumnFilters.EMPLOYEE_MATRICULE] &&
                a[ActivityColumnFilters.EMPLOYEE_MATRICULE]
                  .toLowerCase()
                  .includes(searchTermsLowerCase)) ||
              (a[ActivityColumnFilters.EMPLOYEE_BASE] &&
                a[ActivityColumnFilters.EMPLOYEE_BASE]
                  .toLowerCase()
                  .includes(searchTermsLowerCase)) ||
              (a[ActivityColumnFilters.ACTIVITY_TYPE] &&
                a[ActivityColumnFilters.ACTIVITY_TYPE]
                  .toLowerCase()
                  .includes(searchTermsLowerCase)) ||
              (a[ActivityColumnFilters.FLIGHT_NUMBER] &&
                a[ActivityColumnFilters.FLIGHT_NUMBER]
                  .toLowerCase()
                  .includes(searchTermsLowerCase)) ||
              (a[ActivityColumnFilters.FLIGHT_FROM] &&
                a[ActivityColumnFilters.FLIGHT_FROM]
                  .toLowerCase()
                  .includes(searchTermsLowerCase)) ||
              (a[ActivityColumnFilters.FLIGHT_TO] &&
                a[ActivityColumnFilters.FLIGHT_TO]
                  .toLowerCase()
                  .includes(searchTermsLowerCase)) ||
              (a[ActivityColumnFilters.AUGMENTED_CREW] === true && searchTermsLowerCase.includes(YES.toLowerCase())) ||
              (a[ActivityColumnFilters.AUGMENTED_CREW] === false && searchTermsLowerCase.includes(NO.toLowerCase()))
            );
          });
        }
      }

      setFilteredActivities(filteredActivities);
    }
  }, [
    filters.searchTerm,
    filters.selectedEmployeeIds,
    filters.selectedEmployeeBases,
    filters.selectedActivityTypes,
    filters.selectedFatigueRisks,
    filters.selectedFlightNumbers,
    filters.selectedFromList,
    filters.selectedToList,
    filters.selectedAugmentedCrew,
    myAnalyse,
    setFilters,
  ]);

  const callSetActivitiesListByImport = async (importId) => {
    try {
      const data = await get(`/activity/byImport/${importId}`);
      let mapped = mapMyAnalyseToLocal(data);
      let sortedData = [];
      if (mapped.activities && mapped.activities.length > 0) {
        sortedData = mapped.activities.sort(
          (a, b) => a.fatigueScore - b.fatigueScore
        );
      }
      mapped.activities = sortedData;
      setMyAnalyse(mapped);
    } catch (error) {
      handleHttpError(error);
    }
  };

  const callGetParamsFactorsContribution = async (importId) => {
    try {
      const data = await get(
        `/activity/import/${importId}/contributing-factors`
      );
      let sortedData = [];
      if (data && data.length > 0) {
        sortedData = data.sort((a, b) => a.value - b.value).reverse();
      }
      return sortedData;
    } catch (error) {
      handleHttpError(error);
    }
  };

  const callFatigueLevelDistribution = async (activityId) => {
    try {
      const scoreList = await get(`/activity/${activityId}/fatigue-scores`);
      const fatigueDistribution = countFatigueScoreDistribution(scoreList);
      const filteredFatigueDistribution = fatigueDistribution.filter(
        (element) => element.name !== FatigueStatus.Max.name
      );
      return filteredFatigueDistribution;
    } catch (error) {
      handleHttpError(error);
    }
  };

  const filterSearchTerms = (words) => {
    setFilters((prevState) => ({
      ...prevState,
      searchTerm: words,
    }));
  };

  const filterByFatigueRisk = (fatigueRiskLabels) => {
    setFilters((prevState) => ({
      ...prevState,
      selectedFatigueRisks: fatigueRiskLabels,
    }));
  };

  const filterByEmployeeIds = (employeeIds) => {
    setFilters((prevState) => ({
      ...prevState,
      selectedEmployeeIds: employeeIds,
    }));
  };

  const filterByFlightNumbers = (flightNumbers) => {
    setFilters((prevState) => ({
      ...prevState,
      selectedFlightNumbers: flightNumbers,
    }));
  };

  const filterByFromList = (fromList) => {
    setFilters((prevState) => ({
      ...prevState,
      selectedFromList: fromList,
    }));
  };

  const filterByToList = (toList) => {
    setFilters((prevState) => ({
      ...prevState,
      selectedToList: toList,
    }));
  };

  const filterByActivityTypes = (activityTypes) => {
    setFilters((prevState) => ({
      ...prevState,
      selectedActivityTypes: activityTypes,
    }));
  }

  const filterByAugmentedCrew = (augmentedCrews) => {
    setFilters((prevState) => ({
      ...prevState,
      selectedAugmentedCrew: augmentedCrews,
    }));
  }

  const filterByEmployeeBases = (employeeBases) => {
    setFilters((prevState) => ({
      ...prevState,
      selectedEmployeeBases: employeeBases,
    }));
  }

  const value = useMemo(
    () => ({
      myAnalyse,
      filteredActivities,
      callGetParamsFactorsContribution,
      callFatigueLevelDistribution,
      isLoading,
      filters,
      filterSearchTerms,
      filterByFatigueRisk,
      filterByEmployeeIds,
      filterByActivityTypes,
      filterByFlightNumbers,
      filterByFromList,
      filterByToList,
      filterByAugmentedCrew,
      filterByEmployeeBases,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [myAnalyse, filteredActivities, filters]
  );
  return (
    <AnalyseContext.Provider value={value}>{children}</AnalyseContext.Provider>
  );
};

export const useAnalyse = () => {
  return useContext(AnalyseContext);
};
