import PropTypes from "prop-types";
import { Chip } from "@mui/material";
import { getFatigueStatus } from "../../helpers/fatigueColorHelper";

export default function FatigueStatusChip({
  fatigueScore,
  canClick,
  onClick,
  displayScore = false,
}) {
  const { status, color } = getFatigueStatus(fatigueScore, true);

  return (
    <Chip
      clickable={canClick}
      color="primary"
      label={!displayScore ? status : fatigueScore}
      onClick={onClick}
      style={{ backgroundColor: color }}
    />
  );
}
FatigueStatusChip.propTypes = {
  fatigueScore: PropTypes.string,
  canClick: PropTypes.bool,
  onClick: PropTypes.func,
};
