import { Box, Grid } from "@mui/material";

import CustomPaper from "../../CustomPaper";
import { useActivity } from "../../../hooks/useActivity";
import ActivityContributingFactors from "../fatigue/ActivityContributingFactors";
import { useEffect, useState } from "react";
import Loader from "../../Loader";

export default function ActivityDetailsComponent() {
  const { activity, activityContributionFactors } = useActivity();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (activity) {
      setIsLoading(false);
    }
  }, [activity]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <CustomPaper
            elevation={0}
            square
            style={{ padding: 25 }}
            variant="outlined"
          >
            <ActivityContributingFactors
              activityContributionFactors={activityContributionFactors}
            />
          </CustomPaper>
        </Grid>
      </Grid>
    </Box>
  );
}
