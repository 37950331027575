import { useEffect } from "react";
import { Typography, Grid } from "@mui/material";
// components
import CustomPaper from "../../components/CustomPaper";
import Page from "../../components/Page";
import Loader from "../../components/Loader";
import { useMyAnalyses } from "../../hooks/useMyAnalyses";
import ImportAnalyse from "../../components/app/fatigue-analyses/ImportAnalyse";
import { ModelProvider } from "../../hooks/useModel";
import HeaderDivider from "../../components/HeaderDivider";
import { Colors } from "../../constants/Colors";
import { MessageActions } from "../../constants/MessageActions";
import DisplayContext from "../../components/app/DisplayContext";
import { useAppContext } from "../../hooks/useAppContext";
import { useTimezone } from "../../hooks/useTimezone";
import ScheduleImportList from "../../components/app/fatigue-analyses/ScheduleImportList";
import { onMessageListener } from "../../firebase";
import { toast } from "react-toastify";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import styled from "@emotion/styled";
import { useAuth } from "../../hooks/useAuth";
import { removeStorage } from "../../helpers/storageHelper";
import { StorageKeys } from "../../constants/StorageKeys";

export default function ScheduleImportPage() {
  const { fetchMyAnalyses, myAnalyses } = useMyAnalyses();
  const { timezone } = useTimezone();
  const { auth } = useAuth();
  const {
    appContext: { fatigue_analyses_informations },
  } = useAppContext();

  onMessageListener()
    .then((payload) => {
      const { notification } = payload;
      switch (notification.title) {
        case MessageActions.UPDATE_IMPORT_STATUS:
          fetchMyAnalyses();
          break;
        case MessageActions.ERROR_IMPORT: {
          const { body } = notification;
          fetchMyAnalyses();
          toast.warn(body);
          break;
        }
        default:
          break;
      }
    })
    .catch(() => toast.error("Error while listening notification."));

  useEffect(() => {
    fetchMyAnalyses();
    removeStorage(StorageKeys.FATIGUE_TABLE_SORT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!myAnalyses) {
    return <Loader />;
  }

  const maxImportsLimit = auth.user.maxImportsLimit;
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    marginBottom: 15,
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.primary.brandViolet,
    },
  }));

  return (
    <Page title="Schedule import">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomPaper>
            <Typography
              component="h5"
              style={{ color: Colors.primary }}
              variant="h4"
            >
              Schedule import
            </Typography>
            <HeaderDivider />
            <DisplayContext
              boxStyle={{ marginTop: 0 }}
              isShowed={true}
              text={fatigue_analyses_informations}
              textStyle={{ paddingLeft: 0 }}
            />
          </CustomPaper>
        </Grid>

        <Grid item md={4}>
          <CustomPaper
            elevation={0}
            square
            style={{ padding: 25 }}
            variant="outlined"
          >
            <ModelProvider>
              <ImportAnalyse />
              <BorderLinearProgress
                value={(myAnalyses.length * 100) / maxImportsLimit}
                variant="determinate"
              />
              <Typography variant="p">
                You used{" "}
                <strong>
                  {myAnalyses.length} / {maxImportsLimit}
                </strong>{" "}
                imports
              </Typography>
            </ModelProvider>
          </CustomPaper>
        </Grid>
        <Grid item md={8}>
          <ScheduleImportList
            fatigueAnalyses={myAnalyses}
            timezone={timezone}
          />
        </Grid>
      </Grid>
    </Page>
  );
}
