import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import MultipleSelectChip from "../../MultipleSelectChip";
import { FatigueStatus } from "../../../helpers/fatigueColorHelper";
import { useAnalyse } from "../../../hooks/useAnalyse";
import Loader from "../../Loader";
import { groupByField } from "../../../helpers/arrayHelper";
import CustomButton from "../../CustomButtons";
import SearchTextField from "../../SearchTextField";
import { NO, YES } from "../../../constants/Common";

export default function ActivitiesFilter() {
  const {
    myAnalyse,
    filters,
    filterSearchTerms,
    filteredActivities,
    filterByFatigueRisk,
    filterByEmployeeIds,
    filterByEmployeeBases,
    filterByActivityTypes,
    filterByFlightNumbers,
    filterByFromList,
    filterByToList,
    filterByAugmentedCrew,
  } = useAnalyse();
  const [isLoading, setIsLoading] = useState(true);
  const [isSearchDisplayed, setIsSearchDisplayed] = useState(false);
  const [areFiltersDisplayed, setAreFiltersDisplayed] = useState(false);

  // Filter's lists
  const [fatigueStatusList] = useState([
    FatigueStatus.Low.status,
    FatigueStatus.Medium.status,
    FatigueStatus.High.status,
  ]);
  const [employeeIdsList, setEmployeeIdsList] = useState([]);
  const [employeeBasesList, setEmployeeBasesList] = useState([]);
  const [activityTypesList, setActivityTypesList] = useState([]);
  const [flightNumbersList, setFlightNumbersList] = useState([]);
  const [fromList, setFromList] = useState([]);
  const [toList, setToList] = useState([]);
  const [augmentedCrewList] = useState([
    YES,
    NO
  ]);

  useEffect(() => {
    if (myAnalyse && myAnalyse.activities && filteredActivities) {
      setEmployeeIdsList(
        groupByField(myAnalyse.activities, "employee_matricule")
      );
      setEmployeeBasesList(
        groupByField(myAnalyse.activities, "employee_base")
      );
      setActivityTypesList(groupByField(myAnalyse.activities, "activity_type"));
      setFlightNumbersList(groupByField(myAnalyse.activities, "flight_number"));
      setFromList(groupByField(myAnalyse.activities, "flight_from"));
      setToList(groupByField(myAnalyse.activities, "flight_to"));

      setIsLoading(false);
    }
  }, [filteredActivities, myAnalyse]);

  return isLoading ? (
    <Loader />
  ) : (
    <Box>
      <Box alignItems="center" display="flex" justifyContent="center">
        <CustomButton
          icon={isSearchDisplayed ? <CloseIcon /> : <SearchIcon />}
          label="Search"
          onClick={() =>
            setIsSearchDisplayed((isSearchDisplayed) => !isSearchDisplayed)
          }
          style={{ marginRight: 10 }}
        />
        <CustomButton
          icon={areFiltersDisplayed ? <CloseIcon /> : <FilterAltIcon />}
          label="Filter"
          onClick={() =>
            setAreFiltersDisplayed(
              (areFiltersDisplayed) => !areFiltersDisplayed
            )
          }
        />
      </Box>
      {isSearchDisplayed && (
        <Box
          alignItems="center"
          autoComplete="off"
          component="form"
          display="flex"
          justifyContent="center"
          noValidate
          paddingTop={2}
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
          }}
        >
          <SearchTextField
            filterSearchTerms={filterSearchTerms}
            searchTerm={filters.searchTerm}
          />
        </Box>
      )}
      {areFiltersDisplayed && (
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          paddingTop={2}
        >
          <MultipleSelectChip
            items={fatigueStatusList}
            selectorName="Fatigue risks"
            setItemName={filterByFatigueRisk}
          />
          <MultipleSelectChip
            items={employeeIdsList}
            selectorName="Employee IDs"
            setItemName={filterByEmployeeIds}
          />
          <MultipleSelectChip
            items={employeeBasesList}
            selectorName="Employee Bases"
            setItemName={filterByEmployeeBases}
          />
          <MultipleSelectChip
            items={activityTypesList}
            selectorName="Activity types"
            setItemName={filterByActivityTypes}
          />
          <MultipleSelectChip
            items={flightNumbersList}
            selectorName="Flight numbers"
            setItemName={filterByFlightNumbers}
          />
          <MultipleSelectChip
            items={fromList}
            selectorName="Flights from"
            setItemName={filterByFromList}
          />
          <MultipleSelectChip
            items={toList}
            selectorName="Flights to"
            setItemName={filterByToList}
          />
          <MultipleSelectChip
            items={augmentedCrewList}
            selectorName="Augmented crew"
            setItemName={filterByAugmentedCrew}
          />
        </Box>
      )}
    </Box>
  );
}
