import AppRoutes from "./AppRoutes";
import { AuthProvider } from "./hooks/useAuth";
import { TimezoneProvider } from "./hooks/useTimezone";

function App() {
  return (
    <TimezoneProvider>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </TimezoneProvider>
  );
}

export default App;
