
const Colors = {
  primary: "#001a45",
  secondary: "#00B9ED",
  thirdy: "#072F5B",
  lightBlue: "#125FAB",
  lightGreen: "#29B8C5",
  violet: "#41328B",
  action: "#EF0B69"
};

export { Colors };
