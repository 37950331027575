import PropTypes from "prop-types";
import ReactDOMServer from "react-dom/server";
import DOMPurify from "dompurify";
import { Typography } from "@mui/material";
import { convertUtcToLocale } from "../../../helpers/dateHelper";
import { ActivityTypes } from "../../../constants/ActivityTypes";
import { getFatigueStatus } from "../../../helpers/fatigueColorHelper";

export default function FlightInformationTooltip(
  event,
  extendedProps,
  timezone
) {
  const { title, start, end } = event;
  const { activity_type, fatigueScore, flight_number } = extendedProps;
  const fatigueStatus = getFatigueStatus(fatigueScore, true);

  let contentToDisplay = null;
  switch (activity_type) {
    case ActivityTypes.FLIGHT:
      contentToDisplay = (
        <div>
          <Typography color="inherit" variant="body2">
            <b>{ActivityTypes.FLIGHT}</b>
          </Typography>
          <Typography color="inherit" variant="body1">
            <b>{flight_number}</b>
          </Typography>
          <Typography color="inherit" variant="body1">
            <b>{title}</b>
          </Typography>
          <hr />
          <Typography color="inherit" variant="body1">
            {convertUtcToLocale(start, timezone)}
          </Typography>
          <Typography color="inherit" variant="body1">
            {convertUtcToLocale(end, timezone)}
          </Typography>
          <div
            style={{
              display: "inline-block",
              padding: "0 20px",
              height: "25px",
              fontSize: "14px",
              fontWeight: "bold",
              lineHeight: "25px",
              borderRadius: "25px",
              backgroundColor: fatigueStatus.color,
              color: "#fff",
            }}
          >
            {fatigueScore}
          </div>
        </div>
      );
      break;
    case ActivityTypes.POSITIONING:
    case ActivityTypes.SIMULATOR:
    case ActivityTypes.TRAINING:
    case ActivityTypes.STAND_BY:
    case ActivityTypes.OFFICE:
      contentToDisplay = (
        <div>
          <Typography color="inherit" variant="body1">
            <b>{title}</b>
          </Typography>
          <hr />
          {event.from && event.to && (
            <Typography color="inherit" variant="body1">
              <b>
                {event.from} - {event.to}
              </b>
            </Typography>
          )}
          <Typography color="inherit" variant="body1">
            {convertUtcToLocale(start, timezone)}
          </Typography>
          <Typography color="inherit" variant="body1">
            {convertUtcToLocale(end, timezone)}
          </Typography>
          <Typography color="inherit" variant="body1">
            <b>Predicted fatigue score: {fatigueScore}</b>
          </Typography>
        </div>
      );
      break;
    default:
      contentToDisplay = (
        <div>
          <Typography color="inherit" variant="body1">
            <b>{title}</b>
          </Typography>
          <hr />
          <Typography color="inherit" variant="body1">
            {convertUtcToLocale(start, timezone)}
          </Typography>
          <Typography color="inherit" variant="body1">
            {convertUtcToLocale(end, timezone)}
          </Typography>
        </div>
      );
      break;
  }
  return ReactDOMServer.renderToString(
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(
          ReactDOMServer.renderToString(contentToDisplay)
        ),
      }}
    />
  );
}
FlightInformationTooltip.propTypes = {
  event: PropTypes.object,
  extendedProps: PropTypes.any,
  timezone: PropTypes.string,
};
