import { useState } from "react";
import axios from "axios";
import { getStorage } from "../helpers/storageHelper";
import { appConfig } from "../config";
import { AppEnvironments } from "../constants/AppEnvironments";
import { StorageKeys } from "../constants/StorageKeys";
import useHttpError from "./useHttpError";

const useAxios = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { handdleHttpError } = useHttpError();

  axios.interceptors.request.use(
    async (config) => {
      if (config.url.indexOf("http") === -1) {
        config.url = `${process.env.REACT_APP_API_URL}/api${config.url}`;
      }

      if (typeof config.headers.Authorization === "undefined") {
        const token = getStorage(StorageKeys.TOKEN);
        config.headers.Authorization = `Bearer ${token}`;
      }

      if (appConfig.env === AppEnvironments.TEST) {
        config.headers["ngrok-skip-browser-warning"] = true;
        config.headers['Access-Control-Allow-Origin'] = '*';
        config.headers['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS';
        config.headers['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';
      }

      return config;
    },
    (error) => handdleHttpError(error)
  );

  return {
    isLoading,
    get: async (url, config) => {
      try {
        const response = await axios.get(url, config);
        return response.data;
      } catch (error) {
        setIsLoading(false);
        return error.response;
      } finally {
        setIsLoading(false);
      }
    },
    remove: async (url, config) => {
      try {
        const response = axios.delete(url, config);
        return response.data;
      } catch (error) {
        setIsLoading(false);
        throw error.response;
      } finally {
        setIsLoading(false);
      }
    },
    post: async (url, data, config) => {
      try {
        const response = await axios.post(url, data, config);
        return response.data;
      } catch (error) {
        setIsLoading(false);
        throw error?.response;
      } finally {
        setIsLoading(false);
      }
    },
    put: async (url, data, config) => {
      try {
        const response = await axios.put(url, data, config);
        return response.data;
      } catch (error) {
        setIsLoading(false);
        return error.response;
      } finally {
        setIsLoading(false);
      }
    },
    patch: async (url, data, config) => axios.patch(url, data, config),
  };
};

export default useAxios;
