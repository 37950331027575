import { Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Loader from "../components/Loader";
import Page from "../components/Page";
import { useAuth } from "../hooks/useAuth";
import { appConfig } from "../config";
import { AppEnvironments } from "../constants/AppEnvironments";

const backendUrl = process.env.REACT_APP_API_URL;
const TIME_BEFORE_REDIRECT_MS = 10000;

const LoginRedirect = () => {
  const [text, setText] = useState("Loading...");
  const [errorText, setErrorText] = useState(null);
  const { handleLogin, handleLogout } = useAuth();
  const location = useLocation();
  const params = useParams();
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetch(
      `${backendUrl}/api/auth/${params.providerName}/callback${location.search}`,
      appConfig.env === AppEnvironments.TEST
        ? {
          headers: {
            "ngrok-skip-browser-warning": true,
            "Access-Control-Allow-Origin": "*",
          },
        }
        : null
    )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
        }
        return res;
      })
      .then((res) => res.json())
      .then((res) => {
        handleLogin(res.user, res.jwt);
        setText(
          "You have been successfully logged in. You will be redirected in a few seconds..."
        );
        setTimeout(() => navigate("/app/schedule-import"), 100);
      })
      .catch(() => {
        if (searchParams.get("error_description")) {
          setErrorText(searchParams.get("error_description"));
        } else {
          setErrorText(`An error occurred. Please try later.`);
        }
        setTimeout(() => handleLogout(), TIME_BEFORE_REDIRECT_MS);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, params.providerName]);

  return (
    <Page title="Sign-in ...">
      <Container maxWidth="xl">
        <Loader />
        <Typography sx={{ mb: 5 }} variant="h5">
          {errorText || text}
        </Typography>
        {errorText && (
          <Container maxWidth="m">
            <Typography sx={{ mb: 4 }}>
              {`You'll be redirected to Sign In page in `}
              {TIME_BEFORE_REDIRECT_MS / 1000}s...
            </Typography>
          </Container>
        )}
      </Container>
    </Page>
  );
};

export default LoginRedirect;
