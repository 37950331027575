import {
  Box,
  Button,
  CircularProgress,
  Typography,
  TextField,
  useTheme,
  keyframes,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useEffect, useState } from "react";
import {
  MAX_CHAR_LENGTH,
  MIN_CHARS_LENGTH,
  NO_SQL_INJECTION_REGEX,
} from "../../../constants/Emails";
import useEmail from "../../../hooks/useEmail";
import { TEXT_INPUT_ERRORS } from "../../../constants/Errors";

export default function EmailComponent() {
  const [state, setState] = useState({
    message: "",
    textFieldError: null,
    isValid: false,
    messageState: null,
    isLoading: null,
  });
  const { sendEmailToSupport } = useEmail();
  const theme = useTheme();

  useEffect(() => {
    if (state.message && state.message.length > 0) {
      if (state.message.length < MIN_CHARS_LENGTH) {
        setState({
          ...state,
          textFieldError: TEXT_INPUT_ERRORS.TOO_SHORT,
          isValid: false,
        });
      } else if (state.message.length > MAX_CHAR_LENGTH) {
        setState({
          ...state,
          textFieldError: TEXT_INPUT_ERRORS.TOO_LONG,
          isValid: false,
        });
      } else if (NO_SQL_INJECTION_REGEX.test(state.message)) {
        setState({
          ...state,
          textFieldError: TEXT_INPUT_ERRORS.DOLLAR_SIGN_NOT_ALLOWED,
          isValid: false,
        });
      } else {
        setState({ ...state, textFieldError: null, isValid: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.message]);

  const handleChange = (e) => {
    setState({ ...state, message: e.target.value });
  };

  const handleSubmit = async () => {
    if (state.message && state.message.length > 0) {
      setState({ ...state, isLoading: true });
      const res = await sendEmailToSupport(state.message);
      setState({
        ...state,
        messageState: res && res,
        isLoading: false,
        message: "",
      });
    }
  };

  const displayAnimation = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

  return (
    <Box
      style={{
        display: "grid",
        gap: "10px",
        "& .MuiFormControlRoot": {
          display: "grid",
          gap: "20px",
        },
        "& button": {
          maxWidth: "220px",
        },
      }}
    >
      <TextField
        minRows={8}
        multiline
        name="message"
        onChange={handleChange}
        required
        value={state.message}
      />
      <Typography sx={{ color: theme.palette.error.main }}>
        {state.textFieldError}
      </Typography>

      <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
        <Button
          disabled={!state.message || !state.isValid}
          onClick={handleSubmit}
          size="large"
          startIcon={<SendIcon />}
          type="submit"
          variant="contained"
        >
          Send Message
        </Button>
        {state.isLoading && <CircularProgress size="25px" />}
        {state.messageState && (
          <Box
            sx={{
              transform: "translateY(20px)",
              color: theme.palette.success.main,
              fontSize: "16px",
              animation: `${displayAnimation} .5s cubic-bezier(.47,0,.04,1) forwards`,
            }}
          >
            {state.messageState}
          </Box>
        )}
      </Box>
    </Box>
  );
}
