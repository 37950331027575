import { Typography, Grid } from "@mui/material";
// components
import CustomPaper from "../../components/CustomPaper";
import Page from "../../components/Page";
import HeaderDivider from "../../components/HeaderDivider";
import { Colors } from "../../constants/Colors";
import GlobalTimezoneSelector from "../../components/app/GlobalTimezoneSelector";

export default function ChangeTimezonePage() {
  return (
    <Page title="My timezone">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomPaper>
            <Typography
              component="h5"
              style={{ color: Colors.primary }}
              variant="h4"
            >
              My timezone
            </Typography>
            <HeaderDivider />
          </CustomPaper>
        </Grid>
        <Grid item md={6}>
          <CustomPaper>
            <GlobalTimezoneSelector />
          </CustomPaper>
        </Grid>
      </Grid>
    </Page>
  );
}
