import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useAuth } from "../../hooks/useAuth";

const ProtectedRoute = ({ children }) => {
  const {
    auth: { isAuthenticated },
  } = useAuth();

  if (!isAuthenticated) {
    return <Navigate replace to="/sign-in" />;
  }

  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.any,
};

export default ProtectedRoute;
