import { Box, Typography } from "@mui/material";
import { Colors } from "../../../constants/Colors";
import HeaderDivider from "../../HeaderDivider";
import HorizontalBarChart from "../../HorizontalBarChart";
import { useEffect, useState } from "react";

export default function ActivityContributingFactors({
  activityContributionFactors,
}) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (activityContributionFactors) {
      setIsLoading(false);
    }
  }, [activityContributionFactors]);

  return (
    <Box>
      <Typography component="h6" style={{ color: Colors.thirdy }} variant="h5">
        Contribution of main fatigue causes (Top 5)
      </Typography>
      <HeaderDivider />
      <HorizontalBarChart
        data={activityContributionFactors}
        isLoading={isLoading}
        xKey="name"
        yKey="value"
      />
    </Box>
  );
}
