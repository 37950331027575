import { NO } from "../constants/Common";

import { YES } from "../constants/Common";

export function applyFilter(array, filter) {
  return array.filter((obj) =>
    Object.entries(filter).every(([prop, find]) => find.includes(obj[prop]))
  );
}

export function groupByField(array, field) {
  return array
    .reduce((accumulator, current) => {
      if (current[field] && !accumulator.includes(current[field])) {
        accumulator.push(current[field]);
      }
      return accumulator;
    }, [])
    .sort();
}

export function isStringIsInArray(array, stringContent) {
  if (array && array.length > 0 && stringContent) {
    return array.some((match) => stringContent.includes(match));
  } else {
    return false;
  }
}

export function isBooleanInArray(array, booleanValue) {
  if (Array.isArray(array) && array.length > 0 && typeof booleanValue === 'boolean') {
    return array.includes(booleanValue);
  } else {
    return false;
  }
}

export function mapYesNoArrayToBoolean(array) {
  if (!Array.isArray(array)) {
    return [];
  }

  return array.map(value => {
    if (typeof value !== 'string') {
      return null;
    }
    const normalizedValue = value.trim().toLowerCase();
    if (normalizedValue === YES.toLowerCase()) return true;
    if (normalizedValue === NO.toLowerCase()) return false;
    return null;
  });
}
