/* eslint-disable indent */
import { createRef, useCallback } from "react";
import Dropzone from "react-dropzone";
import { Box, Button, Typography } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { formatBytes } from "../helpers/mathHelper";

const MAX_SIZE = 1048576;

const useStyles = makeStyles(() => ({
  dropzone: {
    textAlign: "center",
    padding: 20,
    border: "3px dashed #eeeeee",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    marginBottom: 20,
  },
  dropzoneAccept: {
    borderColor: "#107c10",
  },
  containedButton: {
    marginTop: 10,
    color: "#fff",
    marginRight: 10,
    borderRadius: 50,
    backgroundColor: "#C90F5C",
    "&:hover": {
      backgroundColor: "#C90F5C",
      color: "#E4E4E4",
    },
  },
}));

export default function DragDropFile({ setFile }) {
  const classes = useStyles();
  const dropzoneRef = createRef();

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length === 1) {
      setFile(acceptedFiles[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  return (
    <Dropzone
      accept={{
        "text/csv": [".csv"],
      }}
      maxFiles={1}
      maxSize={MAX_SIZE}
      noClick
      noKeyboard
      onDrop={onDrop}
      ref={dropzoneRef}
    >
      {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => {
        const additionalClass = isDragAccept
          ? classes.dropzoneAccept
          : isDragReject
          ? "reject"
          : "";

        return (
          <Box
            {...getRootProps({
              className: `${classes.dropzone} ${additionalClass}`,
            })}
          >
            <input ref={dropzoneRef} {...getInputProps()} />
            <Typography>
              <FileUploadIcon />
            </Typography>
            <Typography>
              <b>{`Drag'n'drop your file here`}</b>
            </Typography>
            <Typography>or</Typography>
            <Button
              className={classes.containedButton}
              onClick={openDialog}
              variant="contained"
            >
              Browse for file
            </Button>
            <Typography style={{ paddingTop: 10 }}>
              {`(Only .csv files, maximum ${formatBytes(
                MAX_SIZE
              )}, are accepted)`}
            </Typography>
          </Box>
        );
      }}
    </Dropzone>
  );
}
DragDropFile.propTypes = {
  setFile: PropTypes.func,
};
