import { createContext, useContext, useEffect, useMemo, useState } from "react";
import useAxios from "./useAxios";
import useHttpError from "./useHttpError";

const ActivityContext = createContext();

export const ActivityProvider = ({ activityId, children }) => {
  const [activity, setActivity] = useState(null);
  const [activityContributionFactors, setActivityContributionFactors] =
    useState([]);
  const { get } = useAxios();
  const { handleHttpError } = useHttpError();

  useEffect(() => {
    try {
      if (activityId) {
        callGetActivity();
        callGetContributingFactorsByActivityId();
      }
    } catch (error) {
      handleHttpError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityId]);

  const callGetActivity = async () => {
    const activityDetails = await get(`/activity/${activityId}`);
    setActivity(activityDetails);
  };

  const callGetContributingFactorsByActivityId = async () => {
    const contributingFactorsDetails = await get(
      `/activity/${activityId}/contributing-factors`
    );
    let sortedFactors = [];
    if (contributingFactorsDetails && contributingFactorsDetails.length > 0) {
      sortedFactors = contributingFactorsDetails
        .sort((a, b) => a.value - b.value)
        .reverse()
        .slice(0, 5);
    }
    setActivityContributionFactors(sortedFactors);
  };

  const value = useMemo(
    () => ({
      activity,
      activityContributionFactors,
    }),
    [activity, activityContributionFactors]
  );
  return (
    <ActivityContext.Provider value={value}>
      {children}
    </ActivityContext.Provider>
  );
};

export const useActivity = () => {
  return useContext(ActivityContext);
};
