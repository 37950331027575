import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Outlet } from "react-router-dom";
import LogOutButton from "../components/LogOutButton";

export default function ErrorLayout() {
  return (
    <>
      <Box style={{ marginBottom: 15 }} sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar sx={{ height: "56px" }}>
            <LogOutButton />
          </Toolbar>
        </AppBar>
      </Box>
      <Outlet />
    </>
  );
}
