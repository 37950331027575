import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

export default function AlertDisplay({ severity, children }) {
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Alert severity={severity}>{children}</Alert>
    </Stack>
  );
}
AlertDisplay.propTypes = {
  severity: PropTypes.oneOf(["success", "info", "warning", "error"]),
  children: PropTypes.any,
};
