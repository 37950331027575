import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { Colors } from "../../../constants/Colors";
import HeaderDivider from "../../../components/HeaderDivider";
import DragDropFile from "../../DragDropFile";
import ImportModal from "./ImportModal";
import AlertDisplay from "../../AlertDisplay";
// hooks
import { useMyAnalyses } from "../../../hooks/useMyAnalyses";
import { useBackdrop } from "../../../hooks/useBackdrop";

const NotAllowedPermission = () => {
  return (
    <Box paddingBottom={3}>
      <AlertDisplay severity="warning">
        <p>
          Notifications are disabled for this browser. Please enable them in
          your browser to import files. Following this way:
        </p>
        <Box
          component="img"
          src="/static/images/ask-browser-notifications.gif"
          sx={{
            height: 150,
            width: 300,
          }}
        />
      </AlertDisplay>
    </Box>
  );
};

export default function ImportAnalyse() {
  const { importAnalyse } = useMyAnalyses();
  const [file, setFile] = useState(null);
  const { handleOpenBackdrop, handleCloseBackdrop } = useBackdrop();

  const uploadFile = async (modelId, reason) => {
    if (!modelId || (reason && reason === "backdropClick" && "escapeKeyDown"))
      return;

    if (file) {
      try {
        const fileToUpload = file;

        setFile(null);
        handleOpenBackdrop("We analyze your file. Please wait ...");
        await importAnalyse(fileToUpload, modelId);
        handleCloseBackdrop();
      } catch (e) {
        handleCloseBackdrop();
      }
    }
  };

  return Notification.permission === "granted" ? (
    <Box>
      <Typography component="h6" style={{ color: Colors.primary }} variant="h5">
        Import your file
      </Typography>
      <HeaderDivider />
      <Box>
        <DragDropFile setFile={setFile} />
      </Box>
      {file && <ImportModal file={file} handleClose={uploadFile} />}
    </Box>
  ) : (
    <NotAllowedPermission />
  );
}
