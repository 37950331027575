import { useState } from "react";
import PropTypes from "prop-types";
import { CircularProgress, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { convertUtcToLocale } from "../../../helpers/dateHelper";
import DataTable from "../../Datatable";
import AlertDialog from "../../AlertDialog";
import { useMyAnalyses } from "../../../hooks/useMyAnalyses";
import { removeUserPreferences } from "../../../helpers/userPreferenceHelper";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";

export default function ScheduleImportList({ fatigueAnalyses, timezone }) {
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [idSelected, setIdSelected] = useState(null);
  const { removeImport } = useMyAnalyses();
  const navigate = useNavigate();

  const onDelete = (id) => {
    setIdSelected(id);
    setIsAlertOpen(true);
  };

  const handleDeleteEvent = async () => {
    if (idSelected) {
      await removeImport(idSelected);
      setIsAlertOpen(false);
    }
  };

  const handleGoToScheduledImport = (rowData, index) => {
    if (index.colIndex !== 5) {
      removeUserPreferences();
      navigate(
        `/app/fatigue-analyses/${fatigueAnalyses[index.dataIndex].uuid}`
      );
    }
  };

  const columns = [
    {
      name: "",
      options: {
        display: true,
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return fatigueAnalyses[dataIndex].is_completed ? (
            <CheckCircleIcon style={{ color: "#7cb342" }} />
          ) : (
            <CircularProgress
              color="inherit"
              size={20}
              style={{ marginLeft: "3px", marginBottom: "2px" }}
            />
          );
        },
      },
    },
    {
      name: "filename",
      label: "Filename",
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: "model_name",
      label: "Model",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          if (fatigueAnalyses[dataIndex].is_custom) {
            return <span>{fatigueAnalyses[dataIndex].model_name} (Custom)</span>;
          } else {
            return <span>{fatigueAnalyses[dataIndex].model_name}</span>;
          }
        }
      },
    },
    {
      name: "nbr_analyses",
      label: "Nbr of analysis",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          if (fatigueAnalyses[dataIndex].is_completed) {
            return <span>{fatigueAnalyses[dataIndex].nbr_analyses}</span>;
          } else {
            return <span>In progress...</span>;
          }
        },
      },
    },
    {
      name: "created_at",
      label: "Created at",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return <span>{convertUtcToLocale(value, timezone)}</span>;
        },
      },
    },
    {
      name: "Delete",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const uuid = fatigueAnalyses[dataIndex].uuid;
          return (
            <IconButton
              aria-label="delete"
              disabled={!fatigueAnalyses[dataIndex].is_completed}
              onClick={() => onDelete(uuid)}
            >
              <DeleteIcon />
            </IconButton>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
    filterType: "dropdown",
    viewColumns: false,
    pagination: false,
    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
    download: false,
    filter: false,
    print: false,
    search: false,
    rowHover: false,
    onCellClick: handleGoToScheduledImport,
  };
  return (
    <>
      <DataTable
        columns={columns}
        data={fatigueAnalyses}
        options={options}
        title="Imported files"
      />
      <AlertDialog
        onConfirm={() => handleDeleteEvent()}
        open={isAlertOpen}
        setOpen={setIsAlertOpen}
        title="Delete Analyse?"
      >
        Are you sure you want to delete this fatigue analyse?
      </AlertDialog>
    </>
  );
}
ScheduleImportList.propTypes = {
  fatigueAnalyses: PropTypes.array,
  timezone: PropTypes.string,
};
