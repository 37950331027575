const ActivityColumnFilters = {
  FATIGUE_RISK: "fatigueRisk",
  EMPLOYEE_MATRICULE: "employee_matricule",
  EMPLOYEE_BASE: "employee_base",
  ACTIVITY_TYPE: "activity_type",
  FLIGHT_NUMBER: "flight_number",
  FLIGHT_FROM: "flight_from",
  FLIGHT_TO: "flight_to",
  AUGMENTED_CREW: "augmented_crew"
};

export { ActivityColumnFilters };
