import useAxios from "./useAxios";
import * as DOMPurify from "dompurify";
import useHttpError from "./useHttpError";

const useEmail = () => {
  const { post } = useAxios();
  const { handleHttpError } = useHttpError();

  const sendEmailToSupport = async (query) => {
    try {
      const message = DOMPurify.sanitize(query);
      return await post(`/email/contact-support`, { message });
    } catch (error) {
      handleHttpError(error);
    }
  };

  return { sendEmailToSupport };
};

export default useEmail;
