import { toast } from "react-toastify";

const useHttpError = () => {
  const errorsCodeMessage = [
    { code: 400, message: "Bad request error occured." },
    { code: 403, message: "You don't have enough permissions to use the app." },
    { code: 500, message: "An internal error occurend" },
  ];

  return {
    handleHttpError: (error) => {
      const errorCode = error.status || error.response.status;
      const errorToShow = errorsCodeMessage.find(
        (errorCodeMessage) => errorCodeMessage.code === errorCode
      );
      let errorMessage = errorToShow.message;
      if (error?.data?.error?.details) {
        errorMessage += ` ${error.data.error.details}`;
      }

      toast.error(errorMessage, {
        toastId: "error" + errorToShow.code,
      });
    },
  };
};

export default useHttpError;
