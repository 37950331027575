import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import { Box, AppBar, Toolbar, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useTimezone } from "../../hooks/useTimezone";
import { isMobile } from "react-device-detect";

const APPBAR_MOBILE = 80;
const APPBAR_DESKTOP = 80;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)",
  backgroundColor: alpha(theme.palette.primary.main),
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  justifyContent: "space-between",
  minHeight: `${APPBAR_MOBILE}px !important`,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

Header.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function Header({ onOpenSidebar }) {
  const { timezone } = useTimezone();
  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton
          onClick={onOpenSidebar}
          sx={{ mr: 1, color: "primary.text" }}
        >
          <MenuIcon />
        </IconButton>
        <Box alignItems="center" display="flex">
          <Box>
            <AccessTimeIcon />
          </Box>
          <Box style={{ paddingLeft: 5 }}>{timezone}</Box>
        </Box>
        <Box>
          <img
            alt="logo"
            src={
              isMobile
                ? process.env.PUBLIC_URL + "/static/images/logo.png"
                : process.env.PUBLIC_URL + "/static/images/logo-with-text.png"
            }
            style={{ maxHeight: "40px" }}
          />
        </Box>
      </ToolbarStyle>
    </RootStyle>
  );
}
