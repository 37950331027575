import { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import momentPlugin from "@fullcalendar/moment";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import "bootstrap-icons/font/bootstrap-icons.css";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import "./timeline.css";

import { fullcalendarConfig } from "../../../config";
import FlightInformationTooltip from "../flights/FlightInformationTooltip";
import {
  getUserPreferences,
  setUserPreferences,
} from "../../../helpers/userPreferenceHelper";
import { TimelineViews } from "../../../constants/TimelineViews";
import { ActivityTypes } from "../../../constants/ActivityTypes";
import { ActivityProvider } from "../../../hooks/useActivity";
import ActivityDetailsComponent from "../activities/ActivityDetailsComponent";
import { useModal } from "../../../hooks/useModal";

export default function Timeline({
  analyseId,
  resources,
  events,
  initialTimeZone,
}) {
  const navigate = useNavigate();
  const calendarRef = useRef(null);
  const [preferences, setPreferences] = useState(getUserPreferences());
  const { handleOpenModal, modalType } = useModal();

  useEffect(() => {
    setUserPreferences(preferences);
  }, [preferences]);

  useEffect(() => {
    if (calendarRef && events) {
      let defaultDate = getFirstDateOfEvents();

      if (
        preferences &&
        preferences.timeline &&
        preferences.timeline.activeStart
      ) {
        defaultDate = new Date(preferences.timeline.activeStart);
      }
      handleScroolToTime(defaultDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarRef, events]);

  const getFirstDateOfEvents = () => {
    if (events && events.length > 0) {
      return new Date(Math.min(...events.map((e) => new Date(e.start))));
    } else {
      return Date.now();
    }
  };

  const handleUserCalendarPreferences = ({ view }) => {
    let calendarApi;
    if (calendarRef.current) {
      calendarApi = calendarRef.current.getApi();
    }

    setPreferences({
      timeline: {
        view: view.type,
        activeStart: calendarApi ? calendarApi.view.activeStart : null,
      },
    });
  };

  const handleInitialView = () => {
    if (preferences) {
      const { timeline } = preferences;
      return timeline.view;
    } else {
      return TimelineViews.WEEK;
    }
  };

  const handleScroolToTime = (date) => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(date);
    }
  };

  const handlePopupEvents = (eventInfo) => {
    if (eventInfo.el && eventInfo.event) {
      const currentEvent = eventInfo.event;

      const toolTipContent = FlightInformationTooltip(
        currentEvent,
        currentEvent.extendedProps,
        initialTimeZone
      );
      tippy(eventInfo.el, {
        touch: "hold",
        allowHTML: true,
        content: toolTipContent,
      });
    }
  };

  const handleEventClick = (eventInfo) => {
    const { event } = eventInfo;
    if (event) {
      const { extendedProps } = event;

      if (extendedProps.activity_type === ActivityTypes.FLIGHT) {
        const activityId = event.id;

        if (analyseId && activityId && navigate) {
          const modalChild = (
            <ActivityProvider activityId={activityId}>
              <ActivityDetailsComponent />
            </ActivityProvider>
          );
          handleOpenModal({
            type: modalType.info,
            isClosingCrossVisible: true,
            title: "Fatigue Analysis Details",
            children: modalChild,
          });
        }
      }
    }
  };

  const customButtons = {
    goToFirstEventDateButton: {
      icon: "bi bi-calendar",
      click: () => {
        handleScroolToTime(getFirstDateOfEvents());
      },
    },
  };

  const renderEventContent = (arg) => {
    return <span>{arg.event.title}</span>;
  };

  return (
    <FullCalendar
      aspectRatio={1.5}
      contentHeight="auto"
      customButtons={customButtons}
      datesSet={handleUserCalendarPreferences}
      editable={false}
      eventClick={handleEventClick}
      eventContent={renderEventContent}
      eventDidMount={handlePopupEvents}
      eventOverlap={true}
      events={events}
      firstDay={1}
      headerToolbar={{
        left: "prev,goToFirstEventDateButton,next today",
        center: "title",
        right: "resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth",
      }}
      initialView={handleInitialView()}
      lazyFetching={true}
      nowIndicator={true}
      plugins={[
        bootstrap5Plugin,
        momentPlugin,
        momentTimezonePlugin,
        resourceTimelinePlugin,
      ]}
      ref={calendarRef}
      resourceAreaHeaderContent="Employees"
      resourceAreaWidth="10%"
      resources={resources}
      schedulerLicenseKey={fullcalendarConfig.licenceKey}
      selectable={false}
      timeZone={initialTimeZone}
      views={{
        resourceTimelineDay: {
          slotLabelFormat: [
            { hour: "2-digit", minute: "2-digit", hour12: false },
          ],
        },
        resourceTimelineWeek: {
          slotLabelFormat: [
            { day: "2-digit", month: "2-digit" },
            { hour: "2-digit", minute: "2-digit", hour12: false },
          ],
          duration: { weeks: 1 },
          slotDuration: { hours: 6 },
        },
        resourceTimelineMonth: {
          slotLabelFormat: [{ day: "2-digit", month: "2-digit" }],
          slotDuration: { hours: 24 },
        },
      }}
    />
  );
}
