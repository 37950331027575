const ActivityTypes = {
  FLIGHT: "Flight",
  HOTEL: "Hotel",
  MEP: "Mep",
  GROUND_DUTY: "Ground Duty",
  POSITIONING: "Positioning",
  TRAINING: "Training",
  SIMULATOR: "Simulator",
  STAND_BY: "Standby",
  OFFICE: "Office",
};

export { ActivityTypes };
