import { createContext, useContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  mapFatigueAnalysesToLocal,
  mapFatigueAnalyseToLocal,
} from "../utils/analyseMappers";
import useAxios from "./useAxios";
import useHttpError from "./useHttpError";
import { useModal } from "./useModal";

const MyAnalysesContext = createContext();

export const MyAnalysesProvider = ({ children }) => {
  const [myAnalyses, setMyAnalyses] = useState([]);
  const { get, post, remove, isLoading } = useAxios();
  const { handleHttpError } = useHttpError();
  const { handleOpenModal, modalType } = useModal();

  const fetchMyAnalyses = async () => {
    try {
      const analyses = await get("/import/me");
      if (analyses) {
        const mappedAnalyses = mapFatigueAnalysesToLocal(analyses);
        setMyAnalyses(mappedAnalyses);
      } else {
        toast.warn(`No analyses found`);
      }
    } catch (error) {
      handleHttpError(error);
    }
  };

  const importAnalyse = async (file, modelId) => {
    let formData = new FormData();
    try {
      formData.append(
        "file",
        new File([file], file.name, { type: "text/csv" })
      );
      const importedAnalyse = await post(`/import/upload/${modelId}`, formData);
      let mappedAnalyse;
      if (importedAnalyse) {
        mappedAnalyse = mapFatigueAnalyseToLocal(importedAnalyse);
        setMyAnalyses((myAnalyses) => [...myAnalyses, mappedAnalyse]);
      }
      return mappedAnalyse;
    } catch (error) {
      if (error?.data?.error?.details?.businessError) {
        const businessError = error?.data?.error?.details?.businessError;
        handleOpenModal({
          type: modalType.error,
          isOkButtonVisible: true,
          title: "At least one error occured while importing your file",
          subTitle: "Please try again after correcting the following",
          body: businessError,
        });
      } else {
        handleHttpError(error);
      }
    }
  };

  const removeImport = async (importId) => {
    try {
      await remove(`/import/${importId}`);
      setMyAnalyses(myAnalyses.filter((item) => item.uuid !== importId));
    } catch (error) {
      handleHttpError(error);
    }
  };

  const value = useMemo(
    () => ({
      myAnalyses,
      isLoading,
      fetchMyAnalyses,
      importAnalyse,
      removeImport,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [myAnalyses]
  );
  return (
    <MyAnalysesContext.Provider value={value}>
      {children}
    </MyAnalysesContext.Provider>
  );
};

export const useMyAnalyses = () => {
  return useContext(MyAnalysesContext);
};
