import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  RoundedButton: {
    fontWeight: "bold",
    borderRadius: 50,
    borderColor: "#29B8C5",
  },
  ContainedButton: {
    color: "#fff",
    backgroundColor: "#29B8C5",
    "&:hover": {
      backgroundColor: "#28b1bd",
    },
  },
  OutlinedButton: {
    color: "#29B8C5",
  },
}));

export default function CustomButton({
  variant = "contained",
  label,
  destination,
  style,
  icon,
  onClick = null,
}) {
  const classes = useStyles();
  return (
    <Button
      className={`${classes.RoundedButton} ${
        variant === "contained"
          ? classes.ContainedButton
          : classes.OutlinedButton
      }`}
      component={Link}
      onClick={onClick}
      startIcon={icon}
      style={style}
      to={destination}
      variant={variant}
    >
      {label}
    </Button>
  );
}
CustomButton.propTypes = {
  variant: PropTypes.string,
  label: PropTypes.string,
  destination: PropTypes.string,
  style: PropTypes.object,
  icon: PropTypes.any,
  onClick: PropTypes.func,
};
