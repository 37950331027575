import { useCallback, useEffect, useMemo, useState } from "react";
import DataTable from "../../Datatable";
import FatigueStatusChip from "../FatigueStatusChip";
import { convertUtcToLocale } from "../../../helpers/dateHelper";
import { useAnalyse } from "../../../hooks/useAnalyse";
import { mapToFatigueScoreTable } from "../../../utils/analyseMappers";
import { useAuth } from "../../../hooks/useAuth";
import Loader from "../../Loader";
import { getStorage, setStorage } from "../../../helpers/storageHelper";
import { compareValues } from "../../../helpers/stringHelper";
import { StorageKeys } from "../../../constants/StorageKeys";
import { useModal } from "../../../hooks/useModal";
import { ActivityProvider } from "../../../hooks/useActivity";
import ActivityDetailsComponent from "../activities/ActivityDetailsComponent";
import { NO, YES } from "../../../constants/Common";

export default function FatigueLevelTable({ timezone }) {
  const [isLoading, setIsLoading] = useState(true);
  const { filteredActivities } = useAnalyse();
  const {
    auth: { user },
  } = useAuth();
  const { handleOpenModal, modalType } = useModal();

  const columns = useMemo(
    () => [
      {
        name: "line_number",
        label: "#",
        options: {
          display: user.hasGodMode,
          filter: false,
          sort: true,
        },
      },
      {
        name: "uuid",
        label: "Id",
        options: {
          display: false,
          filter: false,
          sort: false,
        },
      },
      {
        name: "fatigueRisk",
        label: "Fatigue Risk",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, table) => {
            return (
              <FatigueStatusChip
                canClick={false}
                fatigueScore={table.rowData[3]}
              />
            );
          },
        },
      },
      {
        name: "fatigueScore",
        label: "Predicted fatigue score",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "employee_matricule",
        label: "Employee ID",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "employee_base",
        label: "Employee base",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "activity_type",
        label: "Activity type",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "flight_number",
        label: "Flight number",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "flight_from",
        label: "From",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "activity_start",
        label: "Flight start",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <span>{convertUtcToLocale(value, timezone)}</span>;
          },
        },
      },
      {
        name: "flight_to",
        label: "To",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "activity_end",
        label: "Flight end",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <span>{convertUtcToLocale(value, timezone)}</span>;
          },
        },
      },
      {
        name: "augmented_crew",
        label: "Augmented crew",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            if (value === null) {
              return NO;
            }
            return value ? YES : NO;
          },
        },
      },
    ],
    [timezone, user.hasGodMode]
  );

  const DEFAULT_SORT = useMemo(
    () => ({
      name: columns[3].name,
      direction: "desc",
    }),
    [columns]
  );
  const [defaultSort, setDefaultSort] = useState(DEFAULT_SORT);

  const retrieveDefaultSort = useCallback(() => {
    const fatigueTableSort = JSON.parse(
      getStorage(StorageKeys.FATIGUE_TABLE_SORT)
    );
    if (fatigueTableSort) {
      setDefaultSort(fatigueTableSort);
    } else {
      setDefaultSort(DEFAULT_SORT);
    }
  }, [DEFAULT_SORT]);

  useEffect(() => {
    if (filteredActivities) {
      retrieveDefaultSort();
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [filteredActivities, retrieveDefaultSort]);

  const handleGoToDetails = (row) => {
    const rowUuid = row[1];
    if (rowUuid) {
      const modalChild = (
        <ActivityProvider activityId={rowUuid}>
          <ActivityDetailsComponent />
        </ActivityProvider>
      );
      handleOpenModal({
        type: modalType.info,
        isClosingCrossVisible: true,
        title: "Fatigue Analysis Details",
        children: modalChild,
      });
    }
  };

  const handleSort = (data, colIndex, order) => {
    const sort = {
      name: columns[colIndex].name,
      direction: order,
    };
    setStorage(StorageKeys.FATIGUE_TABLE_SORT, JSON.stringify(sort));

    return data.sort((a, b) => {
      const fatigueScoreIndex = columns.findIndex(
        (column) => column.name === "fatigueScore"
      );
      const fatigueRiskIndex = columns.findIndex(
        (column) => column.name === "fatigueRisk"
      );

      const getValue = (data, index) =>
        index === fatigueRiskIndex ? data[fatigueScoreIndex] : data[colIndex];

      const valueA = getValue(a.data, colIndex);
      const valueB = getValue(b.data, colIndex);
      return compareValues(valueA, valueB, order);
    });
  };

  const options = {
    selectableRows: "none",
    filter: false,
    search: false,
    filterType: "dropdown",
    viewColumns: false,
    print: false,
    rowsPerPageOptions: [10, 20, 50, 100],
    sortOrder: defaultSort,
    customSort: handleSort,
    downloadOptions: {
      filterOptions: {
        useDisplayedRowsOnly: true,
      },
    },
    onRowClick: (row) => handleGoToDetails(row),
  };

  const displayTable = () => {
    return (
      <DataTable
        columns={columns}
        data={mapToFatigueScoreTable(filteredActivities)}
        options={options}
      />
    );
  };

  return isLoading ? <Loader /> : displayTable();
}
