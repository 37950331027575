import { makeStyles } from "@mui/styles";
import { Paper } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function CustomPaper({ children }) {
  const classes = useStyles();

  return <Paper className={classes.paper}>{children}</Paper>;
}
