import moment from "moment-timezone";

export const DATETIME_FORMAT_DISPLAY = "YYYY-MM-DD HH:mm";
export const DATETIME_FORMAT_SAVE = "YYYY-MM-DDTHH:mm:ssZ";

export function convertUtcToLocale(
  date,
  timezone,
  format = DATETIME_FORMAT_DISPLAY
) {
  return moment(date).tz(timezone).format(format);
}

export function convertDateToUtc(date) {
  return moment.utc(date).format();
}

export function convertToDate(date) {
  return moment(date);
}

export function getCurrentDateTime() {
  return moment.utc().toISOString();
}

export function getMilliseconds(datetime) {
  return datetime.getMilliseconds();
}

export function getTimestamp(datetime) {
  const dateFormat = new moment.utc(datetime);
  const res = moment(dateFormat).valueOf();
  return res;
}

export function setPreviousVisibleTimeStart(datetime, unit) {
  return moment.utc(datetime).add(-1, unit).startOf(unit).valueOf();
}

export function setPreviousVisibleTimeEnd(datetime, unit) {
  return moment.utc(datetime).add(-1, unit).endOf(unit).valueOf();
}

export function setTimeHeaderStart(unit, date = moment()) {
  return moment.utc(date).startOf(unit).valueOf();
}

export function setTimeHeaderEnd(unit, date = moment()) {
  return moment.utc(date).endOf(unit).valueOf();
}

export function setNextVisibleTimeStart(datetime, unit) {
  return moment.utc(datetime).add(1, unit).startOf(unit).valueOf();
}

export function setNextVisibleTimeEnd(datetime, unit) {
  return moment.utc(datetime).add(1, unit).endOf(unit).valueOf();
}
