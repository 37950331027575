import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography } from "@mui/material";

export default function Loader({ message }) {
  return (
    <Box
      sx={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        marginTop: 4,
        marginBottom: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress color="inherit" disableShrink={true} />
      </Box>
      <Typography variant="body1">{message}</Typography>
    </Box>
  );
}
