import { createContext, useContext, useMemo, useState } from "react";
import Modal from "../components/Modal";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const modalType = {
    error: "error",
    warn: "warn",
    info: "info",
  };

  const [isOpenModal, setOpenModal] = useState(false);
  const [config, setConfig] = useState({
    type: null,
    title: null,
    subTitle: null,
    body: null,
    styles: {},
    isClosingCrossVisible: false,
    isOkButtonVisible: false,
  });

  const buildTemplate = (content, type) => {
    if (content && type === modalType.error) {
      if (Array.isArray(content)) {
        const template = content.map((error, index) => {
          if (typeof error === "string") {
            return <li key={index}>{error}</li>;
          }
          if (typeof error === "object") {
            return Object.keys(error).map((errorName, i) => (
              <li key={i}>
                {errorName} : {error[errorName]}
              </li>
            ));
          }
        });
        return <ul>{template}</ul>;
      } else if (typeof content === "string") {
        return <span>{content}</span>;
      }
    }
  };

  const getStylesByType = (modType) => {
    if (modType === modalType.error) {
      return {
        wordWrap: "break-word",
        "& .MuiPaper-root": {
          boxShadow:
            "0px 11px 15px -7px rgba(255,0,0,0.2),0px 24px 38px 3px rgba(255,0,0,0.14),0px 9px 46px 8px rgba(255,0,0,0.12)",
        },
      };
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleOpenModal = ({
    isOkButtonVisible,
    isClosingCrossVisible,
    type,
    title,
    subTitle,
    body,
    children,
  }) => {
    const template = body ? buildTemplate(body, type) : null;
    setConfig((config) => ({
      ...config,
      isOkButtonVisible,
      isClosingCrossVisible,
      type,
      title,
      subTitle,
      body: template,
      styles: getStylesByType(type),
      children,
    }));
    setOpenModal(true);
  };

  const value = useMemo(
    () => ({
      isOpenModal,
      handleOpenModal,
      modalType,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpenModal]
  );

  return (
    <ModalContext.Provider value={value}>
      <Modal
        handleClose={handleClose}
        isClosingCrossVisible={config.isClosingCrossVisible}
        isOkButtonVisible={config.isOkButtonVisible}
        isOpen={isOpenModal}
        styles={config.styles}
        subTitle={config.subTitle}
        title={config.title}
      >
        {config.body}
        {config.children}
      </Modal>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  return useContext(ModalContext);
};
