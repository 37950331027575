import EmailIcon from "@mui/icons-material/Email";
import HomeIcon from "@mui/icons-material/Home";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import LockIcon from "@mui/icons-material/Lock";

export const topMenu = [
  {
    title: "Schedule import",
    label: "imports",
    path: "/app/schedule-import",
    icon: <HomeIcon />,
  },
];

export const mainMenu = [
  {
    title: "Modify timezone",
    label: "modify-timezone",
    path: "/app/settings/modify-timezone",
    icon: <WatchLaterIcon />,
  },
  {
    title: "Modify my password",
    label: "modify-my-password",
    path: "/app/settings/modify-my-password",
    icon: <LockIcon />,
  },
  {
    title: "Contact us",
    label: "contact-us",
    path: "/app/contact-support",
    icon: <EmailIcon />,
  },
];
