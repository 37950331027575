import { Box, Container, styled, Typography } from "@mui/material";
import Page from "../components/Page";
import { useEffect, useState } from "react";
import { DefaultError, NoCompany } from "../constants/Errors";
import { useAuth } from "../hooks/useAuth";

const TIME_BEFORE_REDIRECT_MS = 10000;

const Content = ({ contentType }) => (
  <>
    <Box
      component="img"
      src={
        contentType === NoCompany.constant
          ? NoCompany.illustrationUrl
          : DefaultError.illustrationUrl
      }
    />
    <Typography variant="h3">
      {contentType === NoCompany.constant
        ? NoCompany.title
        : DefaultError.title}
    </Typography>
    <Typography sx={{ mb: 5 }} variant="h5">
      {contentType === NoCompany.constant
        ? NoCompany.message
        : DefaultError.message}
    </Typography>
  </>
);

export default function ErrorPage({ type }) {
  const [counter, setCounter] = useState(TIME_BEFORE_REDIRECT_MS / 1000);
  const { handleLogout } = useAuth();

  useEffect(() => {
    setTimeout(() => {
      handleLogout();
    }, TIME_BEFORE_REDIRECT_MS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    counter > 0 &&
      setInterval(() => {
        setCounter(counter - 1);
      }, 1000);
  }, [counter]);

  const ErrorPageStyle = styled("div")(({ theme }) => ({
    height: "calc(100vh - 56px)",
    marginTop: "-15px",
    "& .MuiContainer-maxWidthXl": {
      height: "100%",
      display: "grid",
      flexDirection: "column",
      justifyContent: "center",
      paddingTop: "1rem",
    },
    "& .MuiContainer-maxWidthM": {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "center",
    },
    "& img": {
      width: "100vw",
      maxWidth: 200,
      alignSelf: "flex-end",
      justifySelf: "center",
    },
    "& h3": {
      textAlign: "center",
      height: "fit-content",
      alignSelf: "flex-end",
      justifySelf: "center",
      padding: "0 1rem 1rem 1rem",
      fontSize: "2rem",
    },
    "& h5": {
      textAlign: "center",
      padding: "0 1rem",
      fontSize: "1.3rem",
    },
    "& p": {
      bottom: "1rem",
      textAlign: "center",
      color: theme.palette.primary.greenBlue,
    },
  }));

  return (
    <Page title="Your attention is required">
      <ErrorPageStyle>
        <Container maxWidth="xl">
          <Content contentType={type} />
          <Container maxWidth="m">
            <Typography sx={{ mb: 4 }}>
              {`You'll be redirected to Sign In page in ${counter}s...`}
            </Typography>
          </Container>
        </Container>
      </ErrorPageStyle>
    </Page>
  );
}
