import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";
import { ThemeProvider } from "@mui/material";
import { register as registerServiceWorker } from "./serviceWorkerRegistration";
import { ToasterProviderWithConfig } from "./providers/ToasterProviderWithConfig";
import { BackdropProvider } from "./hooks/useBackdrop";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <HelmetProvider>
        <BackdropProvider>
          <App />
          <ToasterProviderWithConfig />
        </BackdropProvider>
      </HelmetProvider>
    </BrowserRouter>
  </ThemeProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
registerServiceWorker();

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("../../firebase-messaging-sw.js")
    .then(function (registration) {
      console.log("Registration was successful: ", registration);
    })
    .catch(function (e) {
      console.error("Registration has filed", e);
    });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
